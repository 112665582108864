// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-navbar {
    background-color: #ffffff;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
}

.mobile-navbar-brand img {
    height: 40px;
}

.mobile-nav-link {
    color: #1f9e7d;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
    display: block;
    margin-bottom: 10px;
}

.collapibleMenu{
    padding: 10% 5%;
    margin-top: 10%;
    background-color: #F8F8F8;
}

.mobile-nav-link:hover {
    color: #1f9e7d;
}

.mobile-buttonNav {
    padding: 8px 15px;
    background-color: #27ca9f;
    color: #ffffff;
    text-decoration: none;
    border: 2px solid #27ca9f;
    border-radius: 5px;
    font-weight: bold;
    margin-right: 10px;
    display: block;
}

.mobile-ghost-button {
    background-color: transparent;
    color: #000000;
    border: 2px solid #000000;
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/styles/mobileNavbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,iBAAiB;IACjB,2BAA2B;IAC3B,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,qBAAqB;IACrB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,cAAc;IACd,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".mobile-navbar {\r\n    background-color: #ffffff;\r\n    padding: 10px 20px;\r\n    border-bottom: 1px solid #ddd;\r\n}\r\n\r\n.mobile-navbar-brand img {\r\n    height: 40px;\r\n}\r\n\r\n.mobile-nav-link {\r\n    color: #1f9e7d;\r\n    text-decoration: none;\r\n    font-weight: bold;\r\n    transition: color 0.3s ease;\r\n    display: block;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.collapibleMenu{\r\n    padding: 10% 5%;\r\n    margin-top: 10%;\r\n    background-color: #F8F8F8;\r\n}\r\n\r\n.mobile-nav-link:hover {\r\n    color: #1f9e7d;\r\n}\r\n\r\n.mobile-buttonNav {\r\n    padding: 8px 15px;\r\n    background-color: #27ca9f;\r\n    color: #ffffff;\r\n    text-decoration: none;\r\n    border: 2px solid #27ca9f;\r\n    border-radius: 5px;\r\n    font-weight: bold;\r\n    margin-right: 10px;\r\n    display: block;\r\n}\r\n\r\n.mobile-ghost-button {\r\n    background-color: transparent;\r\n    color: #000000;\r\n    border: 2px solid #000000;\r\n    display: block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
