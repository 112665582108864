import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { login } from '../redux/slices/auth.slice.ts';

const useFetch = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = process.env.REACT_APP_BEARER_TOKEN;

  const handleGoogle = async (response) => {
    setLoading(true);

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`, 
        },
        body: JSON.stringify({ credential: response.credential }),
      });

      const data = await res.json();

      if (data?.data) {
        const { email, userId, sessionToken } = data.data;
        dispatch(login({ email, userId, sessionToken }));
        navigate("/dashboard");
      } else if (data?.statusCode === 404) {
        setError(data?.message);
      } else {
        throw new Error(error?.message || 'Could not login using your google account. Please try again.');
      }
    } catch (error) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, handleGoogle };
};

export default useFetch;
