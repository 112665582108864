import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, Input, Button, Spinner } from 'reactstrap';
import '../styles/otp.css';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../auth/authContext.tsx';
import AuthService from '../api/AuthService.tsx';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const AccountVerificationPage: React.FC = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const resendTimeoutSeconds = 30;
    const { email } = useAuth();
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);


    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, otp.length);
    }, [otp.length]);

    const getUserIdAndVerifyAccount = async () => {
        try {
            setLoading(true);

            const userId = await AuthService.getUserIdByEmail(email);

            if (userId) {
                const joinedOtp = otp.join('');
                await AuthService.verifyAccount({ userId, otp: joinedOtp });
                toast.success('Account verified successfully!');
                navigate('/signin');

            } else {
                setError('User not found.');
                toast.error('User not found.');
            }
        } catch (error) {
            setError('Error verifying account. Please try again.');
            toast.error('Error verifying account. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleOtpChange = (index: number, value: string) => {
        if (value.match(/^\d*$/)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (inputRefs.current[index + 1]) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleResendOtp = async () => {
        try {
            setResendDisabled(true);
            setLoading(true);
            await AuthService.resendOTP(email);
            toast.success('OTP resent successfully!');
            setTimeout(() => {
                setResendDisabled(false);
                if (inputRefs.current[0]) {
                    inputRefs.current[0]?.focus();
                }
            }, resendTimeoutSeconds * 1000);
        } catch (error) {
            toast.error('Error resending OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='otp-page'>
            <Helmet>
                <title>Verify Account - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Card className="otp-card">
                <CardBody>
                    <h4 className="otp-heading">Verify Your Account To Start Transacting</h4>
                    <p className="otp-subheading" style={{ textAlign: 'center' }}>Enter the OTP sent to {email}</p>
                    <div className='otp-input-group'>
                        {otp.map((digit, index) => (
                            <Input
                                key={index}
                                type="text"
                                value={digit}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                className="otp-input"
                                maxLength={1}
                                innerRef={(ref) => (inputRefs.current[index] = ref)}
                            />
                        ))}
                    </div>
                    {error && <p className="error">{error}</p>}
                    {loading ? (<div className='spinner'>
                        <Spinner
                            color="primary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="secondary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="success"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="danger"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="warning"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="info"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="light"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="dark"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                    </div>) : (
                        <>
                            <div className="text-center mt-3">
                                <Button className='buttonSubmit' style={{ backgroundColor: '#27ca9f' }} onClick={getUserIdAndVerifyAccount} disabled={loading}>
                                    Verify Account
                                </Button>
                            </div>
                            <div className="text-center mt-3">
                                <Button
                                    className='buttonSubmit'
                                    disabled={resendDisabled || loading}
                                    onClick={handleResendOtp}
                                >
                                    Resend OTP
                                </Button>
                            </div>
                        </>
                    )}
                </CardBody>
            </Card>
            <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default AccountVerificationPage;
