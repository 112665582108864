import React, { useEffect, useState } from 'react';
import { Card, CardBody, Form, FormGroup, Label, Input, Button, Row, Col, Progress, Spinner } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import { DatePicker } from "antd";
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';
import 'react-circular-progressbar/dist/styles.css';
import Select from 'react-select';
import Footer from '../components/footer.tsx';
import '../styles/onboarding.css';
import TextArea from 'antd/es/input/TextArea';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../api/AuthService.tsx';
import { useAuth } from '../auth/authContext.tsx';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import logo from '../images/logo.svg';


const OnboardingPage = () => {
    const navigate = useNavigate();
    const { email, password } = useAuth();
    const [currentStep, setCurrentStep] = useState(1);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [genderString, setGenderString] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [nationalID, setNationalID] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [residentCountry, setResidentCountry] = useState('');
    const [loading, setLoading] = useState(false);
    const [taxIdentifier, setTaxIdentifier] = useState('VIRTUAL');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (!email || !password) {
            navigate('/signup');
        }
    }, [email, password, navigate]);

    const handleNext = () => {
        if (currentStep < 3) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrev = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handlePhoneNumberChange = (value: React.SetStateAction<string>) => {
        setPhoneNumber(value);
    };

    const handleGenderChange = (selectedOption: any) => {
        setGender(selectedOption);
        if (selectedOption && selectedOption.value) {
            setGenderString(selectedOption.value);
        }
    };

    const handleDateChange = (date: any, dateString: string) => {
        setDateOfBirth(dateString);
    }


    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        try {
            setLoading(true);

            switch (currentStep) {
                case 1:
                    break;
                case 2:
                    break;
                case 3:
                    const userData = {
                        email,
                        password,
                        firstName,
                        lastName,
                        gender: genderString,
                        dateOfBirth,
                        nationalID,
                        phoneNumber,
                        address,
                        residentCountry,
                        taxIdentifier,
                        username,
                    };

                    await AuthService.register(userData);

                    toast.success('Registration completed successfully!');
                    navigate('/verify-account');
                    break;
                default:
                    break;
            }
            handleNext();
        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const calculateCompletionPercentage = () => {
        const totalSteps = 3;
        return (currentStep / totalSteps) * 100;
    };

    const options = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'Other' },
    ];

    return (
        <>
            <Helmet>
                <title>Onboarding - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <div className="onboarding-container">
                <Card className="onboarding-card">
                    <CardBody>
                        <div className='onboardingLogo'>
                            <img src={logo} alt='logo' className='logo' />
                        </div>
                        <Progress value={calculateCompletionPercentage()} striped color='success' />

                        <hr />

                        <div className="button-container">
                            {currentStep === 1 && (
                                <>
                                    <h4 className='heading'><u>Step 1/3</u></h4>
                                    <p>Please complete the form below. All fields are required!</p>
                                    <hr />
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="firstName">First Name</Label>
                                                    <Input
                                                        type="text"
                                                        id="firstName"
                                                        name="firstName"
                                                        value={firstName}
                                                        placeholder='Enter first name'
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="lastName">Last Name</Label>
                                                    <Input
                                                        type="text"
                                                        id="lastName"
                                                        name="lastName"
                                                        value={lastName}
                                                        placeholder='Enter last name'
                                                        onChange={(e) => setLastName(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="gender">Gender</Label>
                                                    <Select
                                                        id="gender"
                                                        name="gender"
                                                        value={gender}
                                                        options={options}
                                                        onChange={handleGenderChange}
                                                        required
                                                        className='dropdown'
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="dateOfBirth">Date Of Birth</Label>
                                                    <br />
                                                    <DatePicker
                                                        onChange={handleDateChange}
                                                        style={{ width: '213px', height: '40px' }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="nationalID">National Identity</Label>
                                                    <Input
                                                        type="text"
                                                        id="nationalID"
                                                        name="nationalID"
                                                        value={nationalID}
                                                        placeholder='Enter national ID'
                                                        onChange={(e) => setNationalID(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="phoneNumber">Phone Number</Label>
                                                    <PhoneInput
                                                        country={'us'}
                                                        value={phoneNumber}
                                                        onChange={handlePhoneNumberChange}
                                                        inputStyle={{ width: '100%', height: '40px' }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="address">Residential Address</Label>
                                                    <TextArea
                                                        id="address"
                                                        name="address"
                                                        value={address}
                                                        placeholder='Enter residential address'
                                                        onChange={(e) => setAddress(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="residentCountry">Residential Country</Label>
                                                    <TextArea
                                                        id="residentCountry"
                                                        name="residentCountry"
                                                        placeholder='Enter residential country'
                                                        value={residentCountry}
                                                        onChange={(e) => setResidentCountry(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button type="submit" className="buttonSubmit">
                                            Next
                                        </Button>
                                    </Form>
                                </>

                            )}
                            {currentStep === 2 && (
                                <>
                                    <h4 className='heading'><u>Step 2/3</u></h4>
                                    <p>Please upload your national identification card here to continue!</p>
                                    <hr />
                                    <Form onSubmit={handleSubmit}>
                                        <Input
                                            type='file'
                                            size={500}
                                            required
                                        />
                                        <Button type="submit" className="buttonSubmit">
                                            Next
                                        </Button>
                                    </Form>
                                </>
                            )}
                            {currentStep === 3 && (
                                <>
                                    <h4 className='heading'><u>Step 3/3</u></h4>
                                    <p>Please specify a username here to finish setting up your account!</p>
                                    <hr />
                                    <Form onSubmit={handleSubmit}>
                                        <Input
                                            type='text'
                                            required
                                            value={username}
                                            placeholder='Enter username'
                                            onChange={(e) => setUsername(e.target.value)}
                                            aria-required

                                        />
                                        {loading ? (<div className='spinner'>
                                            <Spinner
                                                color="primary"
                                                type="grow"
                                            >
                                                Loading...
                                            </Spinner>
                                            <Spinner
                                                color="secondary"
                                                type="grow"
                                            >
                                                Loading...
                                            </Spinner>
                                            <Spinner
                                                color="success"
                                                type="grow"
                                            >
                                                Loading...
                                            </Spinner>
                                            <Spinner
                                                color="danger"
                                                type="grow"
                                            >
                                                Loading...
                                            </Spinner>
                                            <Spinner
                                                color="warning"
                                                type="grow"
                                            >
                                                Loading...
                                            </Spinner>
                                            <Spinner
                                                color="info"
                                                type="grow"
                                            >
                                                Loading...
                                            </Spinner>
                                            <Spinner
                                                color="light"
                                                type="grow"
                                            >
                                                Loading...
                                            </Spinner>
                                            <Spinner
                                                color="dark"
                                                type="grow"
                                            >
                                                Loading...
                                            </Spinner>
                                        </div>) : (
                                            <>
                                                {error && <div style={{ color: "#ff0000", marginTop: '5%', marginBottom: '5%' }}>{error}</div>}
                                                <Button type="submit" className="buttonSubmit" disabled={loading}>
                                                    Finish
                                                </Button>
                                            </>

                                        )}
                                    </Form>
                                </>
                            )}

                            <div className="navigation-buttons">
                                {currentStep > 1 && (
                                    <Button onClick={handlePrev} className="buttonSubmit" hidden={loading}>
                                        Previous
                                    </Button>
                                )}
                            </div>
                        </div>
                    </CardBody>
                </Card>
                {/* <div className="onboarding-image" style={{ width: '50%', height: '100%' }}></div> */}
                <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            </div>
            <Footer />
        </>

    );
};

export default OnboardingPage;
