import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const SECRET_KEY = process.env.REACT_APP_BEARER_TOKEN;

const initialState = {
    userName: '',
    lastName: '',
    firstName: '',
    email: '',
    phoneNumber: '',
    address: '',
    resident_country: '',
    dateOfBirth: '',
    nationalID: '',
    gender: '',
    accountBalance: 0,
    accountNumber: '',
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            return { ...state, ...action.payload };
        },
        updateBalance: (state, action) => {
            state.accountBalance = action.payload;
        },
        clearProfile: (state) => {
            return initialState;
        },
    },
});

const encryptor = encryptTransform({
    secretKey: SECRET_KEY,
    onError: function (error) {
        return;
    },
});

const persistConfig = {
    key: 'profile',
    storage,
    transforms: [encryptor],
    timeout: null,
};

const persistedProfileReducer = persistReducer(persistConfig, profileSlice.reducer);

export const { setProfile, updateBalance, clearProfile } = profileSlice.actions;
export const selectProfile = (state: { profile: any }) => state.profile;
export default persistedProfileReducer;
