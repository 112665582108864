// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.financial-overview {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }
  
  .overview-card {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    width: 300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    margin-left: 3%;   
  
    &:hover {
      transform: scale(1.05);
    }
  }
  
  .card-icon {
    font-size: 36px;
    margin-bottom: 10px;
    color: #27ca9f;
  }
  
  .card-details h3 {
    font-size: 16px;
    margin: 0;
  }
  
  .card-details p {
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0;
  }

  @media (max-width: 768px) {
    .financial-overview {
        flex-direction: column; 
        align-items: center; 
    }

    .overview-card {
        width: 100%; 
        margin-left: 0; 
        margin-bottom: 2%;
    }
}
  `, "",{"version":3,"sources":["webpack://./src/styles/financial-overview.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,uCAAuC;IACvC,sCAAsC;IACtC,eAAe;;IAEf;MACE,sBAAsB;IACxB;EACF;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,SAAS;EACX;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,aAAa;EACf;;EAEA;IACE;QACI,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;QACI,WAAW;QACX,cAAc;QACd,iBAAiB;IACrB;AACJ","sourcesContent":[".financial-overview {\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n    margin-top: 20px;\r\n  }\r\n  \r\n  .overview-card {\r\n    background-color: #fff;\r\n    border: 1px solid #eaeaea;\r\n    border-radius: 15px;\r\n    padding: 20px;\r\n    text-align: center;\r\n    width: 300px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n    transition: transform 0.2s ease-in-out;\r\n    margin-left: 3%;   \r\n  \r\n    &:hover {\r\n      transform: scale(1.05);\r\n    }\r\n  }\r\n  \r\n  .card-icon {\r\n    font-size: 36px;\r\n    margin-bottom: 10px;\r\n    color: #27ca9f;\r\n  }\r\n  \r\n  .card-details h3 {\r\n    font-size: 16px;\r\n    margin: 0;\r\n  }\r\n  \r\n  .card-details p {\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    margin: 5px 0;\r\n  }\r\n\r\n  @media (max-width: 768px) {\r\n    .financial-overview {\r\n        flex-direction: column; \r\n        align-items: center; \r\n    }\r\n\r\n    .overview-card {\r\n        width: 100%; \r\n        margin-left: 0; \r\n        margin-bottom: 2%;\r\n    }\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
