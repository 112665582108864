import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const SECRET_KEY = process.env.REACT_APP_BEARER_TOKEN;

const initialState = {
    isAuthenticated: false,
    user: {
        email: '',
        sessionToken: '',
        userId: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            const { email, sessionToken, userId } = action.payload;
            state.isAuthenticated = true;
            state.user = { email, sessionToken, userId };
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = {
                email: '',
                sessionToken: '',
                userId: '',
            };
        },
    },
});

const encryptor = encryptTransform({
    secretKey: SECRET_KEY,
    onError: function (error) {
       return;
    },
});

const persistConfig = {
    key: 'auth',
    storage,
    transforms: [encryptor],
    timeout: null,
};

const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer);

export const { login, logout } = authSlice.actions;
export const selectAuth = (state: { auth: any }) => state.auth;
export default persistedAuthReducer;
