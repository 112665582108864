// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/bg-3.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* forgotPassword.css */

.forgot-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
    font-family: 'Catamaran';
}

.forgot-password-card {
    width: 90%; 
    max-width: 450px;
    margin: auto;
}

.forgot-password-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem; 
}

.form-group {
    margin-bottom: 20px;
}

.forgot-password-text{
    text-align: center;
}

.buttonSubmit {
    width: 100%;
    margin-top: 15px;
    background-color: #27ca9f;
}

.buttonSubmit :focus{
    background-color: black;
}

.text-underline {
    text-decoration: underline;
    color: #27ca9f;
    cursor: pointer;
}

.inputField{
    width: '100%';
}

@media (max-width: 768px) {
    .forgot-password-card {
        width: 80%; 
    }
}

@media (max-width: 576px) {
    .forgot-password-card {
        width: 90%; 
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/forgotPassword.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yDAA2C;IAC3C,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,wBAAwB;AAC5B;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,0BAA0B;IAC1B,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":["/* forgotPassword.css */\r\n\r\n.forgot-password-page {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    background-image: url('../images/bg-3.jpg');\r\n    background-size: cover; \r\n    background-position: center; \r\n    background-repeat: no-repeat;\r\n    font-family: 'Catamaran';\r\n}\r\n\r\n.forgot-password-card {\r\n    width: 90%; \r\n    max-width: 450px;\r\n    margin: auto;\r\n}\r\n\r\n.forgot-password-heading {\r\n    text-align: center;\r\n    margin-bottom: 20px;\r\n    font-size: 1.5rem; \r\n}\r\n\r\n.form-group {\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.forgot-password-text{\r\n    text-align: center;\r\n}\r\n\r\n.buttonSubmit {\r\n    width: 100%;\r\n    margin-top: 15px;\r\n    background-color: #27ca9f;\r\n}\r\n\r\n.buttonSubmit :focus{\r\n    background-color: black;\r\n}\r\n\r\n.text-underline {\r\n    text-decoration: underline;\r\n    color: #27ca9f;\r\n    cursor: pointer;\r\n}\r\n\r\n.inputField{\r\n    width: '100%';\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .forgot-password-card {\r\n        width: 80%; \r\n    }\r\n}\r\n\r\n@media (max-width: 576px) {\r\n    .forgot-password-card {\r\n        width: 90%; \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
