// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    background-color: #ffffff;
    padding: 10px 20px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    transition: border-bottom 0.3s ease;
}

.buttonsNav {
    display: flex;
    align-items: center;
}

.buttonNav {
    padding: 8px 15px;
    background-color: #27ca9f;
    color: #ffffff;
    text-decoration: none;
    border: 2px solid #27ca9f;
    border-radius: 5px;
    margin-right: 10px;
}

.ghost-button {
    background-color: transparent;
    color: #27ca9f;
    border: 2px solid #27ca9f;
}

/* Style for active NavLink */
.nav-link:hover,
.nav-link.active {
    color: #000000;
    text-decoration: underline;
}




`, "",{"version":3,"sources":["webpack://./src/styles/navbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,6BAA6B;IAC7B,mCAAmC;AACvC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,qBAAqB;IACrB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,cAAc;IACd,yBAAyB;AAC7B;;AAEA,6BAA6B;AAC7B;;IAEI,cAAc;IACd,0BAA0B;AAC9B","sourcesContent":[".navbar {\r\n    background-color: #ffffff;\r\n    padding: 10px 20px;\r\n    height: 70px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    border-bottom: 1px solid #ddd;\r\n    transition: border-bottom 0.3s ease;\r\n}\r\n\r\n.buttonsNav {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.buttonNav {\r\n    padding: 8px 15px;\r\n    background-color: #27ca9f;\r\n    color: #ffffff;\r\n    text-decoration: none;\r\n    border: 2px solid #27ca9f;\r\n    border-radius: 5px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.ghost-button {\r\n    background-color: transparent;\r\n    color: #27ca9f;\r\n    border: 2px solid #27ca9f;\r\n}\r\n\r\n/* Style for active NavLink */\r\n.nav-link:hover,\r\n.nav-link.active {\r\n    color: #000000;\r\n    text-decoration: underline;\r\n}\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
