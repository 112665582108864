import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../styles/auth-loading.css';

const PrivateRoute = ({ element, ...rest }) => {
    const { isAuthenticated } = useSelector((state: any) => state.auth);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAuthentication = async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setIsLoading(false);
        };

        checkAuthentication();
    }, []);

    if (!isLoading && isAuthenticated) {
        return <Outlet />;
    }

    if (isLoading) {
        return (
            <div className="loading-bar-container">
                <div className="loading-bar"></div>
            </div>
        );
    }

    return <Navigate to="/signin" replace />;
};

export default PrivateRoute;
