// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/bg-8.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
   
}

.ononboardingLogo{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}


.spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.onboarding-card {
    width: 80%;
    max-width: 600px;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
}

.heading {
    margin-top: 10px;
    margin-bottom: 20px;
    color: black;
  
}

.buttonSubmit {
    background-color: #27ca9f;
    color: #fff;
    margin-top: 10px;
}


.dropdown{
    width: 225px;
    height: 40px; 
}

.onboarding-image {
    width: 50%;
    height: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover; 
    background-position: center; 
  }
`, "",{"version":3,"sources":["webpack://./src/styles/onboarding.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,iBAAiB;;AAErB;;AAEA;GACG,aAAa;GACb,sBAAsB;GACtB,uBAAuB;GACvB,mBAAmB;AACtB;;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;;AAEhB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,gBAAgB;AACpB;;;AAGA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yDAA2C;IAC3C,sBAAsB;IACtB,2BAA2B;EAC7B","sourcesContent":[".onboarding-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top: 5%;\r\n    margin-bottom: 5%;\r\n   \r\n}\r\n\r\n.ononboardingLogo{\r\n   display: flex;\r\n   flex-direction: column;\r\n   justify-content: center;\r\n   align-items: center;\r\n}\r\n\r\n\r\n.spinner{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top: 5%;\r\n    margin-bottom: 5%;\r\n}\r\n\r\n.onboarding-card {\r\n    width: 80%;\r\n    max-width: 600px;\r\n    padding: 20px;\r\n    margin: 20px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.button-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    margin-top: 10%;\r\n}\r\n\r\n.heading {\r\n    margin-top: 10px;\r\n    margin-bottom: 20px;\r\n    color: black;\r\n  \r\n}\r\n\r\n.buttonSubmit {\r\n    background-color: #27ca9f;\r\n    color: #fff;\r\n    margin-top: 10px;\r\n}\r\n\r\n\r\n.dropdown{\r\n    width: 225px;\r\n    height: 40px; \r\n}\r\n\r\n.onboarding-image {\r\n    width: 50%;\r\n    height: 100%;\r\n    background-image: url('../images/bg-8.jpg');\r\n    background-size: cover; \r\n    background-position: center; \r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
