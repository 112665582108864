// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-container {
    width: 80%;
    margin: 100px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #333;
    line-height: 1.6;
}

.terms-container h2 {
    color: #27ca9f;
    padding-bottom: 10px;
    border-bottom: 2px solid #27ca9f;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
}

.terms-container ul {
    margin-left: 20px;
    list-style-type: disc;
}

.terms-container li {
    margin-bottom: 10px;
}

.terms-container p {
    margin-bottom: 20px;
    text-align: justify;
    font-size: 16px;
}

.terms-container a {
    color: #27ca9f;
    text-decoration: none;
}

.terms-container a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .terms-container {
        width: 95%;
        margin: 120px auto;
        padding: 15px;
    }

    .terms-container h2 {
        font-size: 20px;
    }

    .terms-container p, .terms-container li {
        font-size: 14px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/terms.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,uCAAuC;IACvC,sBAAsB;IACtB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,gCAAgC;IAChC,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI;QACI,UAAU;QACV,kBAAkB;QAClB,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".terms-container {\r\n    width: 80%;\r\n    margin: 100px auto;\r\n    padding: 20px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n    background-color: #fff;\r\n    color: #333;\r\n    line-height: 1.6;\r\n}\r\n\r\n.terms-container h2 {\r\n    color: #27ca9f;\r\n    padding-bottom: 10px;\r\n    border-bottom: 2px solid #27ca9f;\r\n    margin-top: 20px;\r\n    margin-bottom: 20px;\r\n    font-size: 24px;\r\n}\r\n\r\n.terms-container ul {\r\n    margin-left: 20px;\r\n    list-style-type: disc;\r\n}\r\n\r\n.terms-container li {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.terms-container p {\r\n    margin-bottom: 20px;\r\n    text-align: justify;\r\n    font-size: 16px;\r\n}\r\n\r\n.terms-container a {\r\n    color: #27ca9f;\r\n    text-decoration: none;\r\n}\r\n\r\n.terms-container a:hover {\r\n    text-decoration: underline;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .terms-container {\r\n        width: 95%;\r\n        margin: 120px auto;\r\n        padding: 15px;\r\n    }\r\n\r\n    .terms-container h2 {\r\n        font-size: 20px;\r\n    }\r\n\r\n    .terms-container p, .terms-container li {\r\n        font-size: 14px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
