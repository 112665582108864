import React from 'react';
import { Card, CardBody } from 'reactstrap';
import '../styles/404.css';
import Header from '../components/header.tsx';
import Sidebar from '../components/sidebar.tsx';
import { useSelector } from 'react-redux';

const NotFoundPage = () => {
    const { isAuthenticated } = useSelector((state: any) => state.auth);

    return (
        <>
            {isAuthenticated && (
                <>
                    <Header />
                    <Sidebar />
                </>
            )}
            <div className="notFoundContainer">
                <div className='container'>
                    <Card className='card'>
                        <CardBody>
                            <h2 className='notFoundHeader'>404 - Page Not Found</h2>
                            <p className='notFoundParagraph'>Sorry, the page you are looking for does not exist. <br />You could lend us a hand in finding it, but then we'd owe you one!</p>
                            <p className='notFoundRedirect'>
                                <a href="/" onClick={() => window.history.push('/')} style={{color: '#27ca9f', cursor: 'pointer'}}>
                                    Click here to go back to the homepage.
                                </a>
                            </p>
                        </CardBody>

                    </Card>
                </div>
            </div>
        </>
    );
};

export default NotFoundPage;
