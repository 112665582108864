// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2%;
}

.rootContainer {
   margin-top: 5%;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column; 
    }
    .rootContainer {
        margin-top: 40%;
    }
}

.container.hide-sidebar {
    @media (max-width: 768px) {
      margin-left: 0; 
    }
  }
`, "",{"version":3,"sources":["webpack://./src/styles/home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;GACG,cAAc;AACjB;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;MACE,cAAc;IAChB;EACF","sourcesContent":[".container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding-bottom: 2%;\r\n}\r\n\r\n.rootContainer {\r\n   margin-top: 5%;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .container {\r\n        flex-direction: column; \r\n    }\r\n    .rootContainer {\r\n        margin-top: 40%;\r\n    }\r\n}\r\n\r\n.container.hide-sidebar {\r\n    @media (max-width: 768px) {\r\n      margin-left: 0; \r\n    }\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
