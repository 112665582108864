// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-transactions {
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #27ca9f;
    color: #fff;
}

tr:hover {
    background-color: rgba(39, 202, 159, 0.1);
}

.transaction-icon {
    margin-right: 10px;
    color: #27ca9f;
}

.noTransactions{
    font-size: 20px;
    margin-left: 15%;
}



@media (max-width: 768px) {
    .financial-overview {
        flex-direction: column; 
        align-items: center; 
    }

    .overview-card {
        width: 100%; 
        margin-left: 0; 
        margin-bottom: 2%;
    }

    .noTransactions{
        font-size: 20px;
        text-align: center;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/recent-transactions.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;;;AAIA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;QACI,WAAW;QACX,cAAc;QACd,iBAAiB;IACrB;;IAEA;QACI,eAAe;QACf,kBAAkB;IACtB;AACJ","sourcesContent":[".recent-transactions {\r\n    margin-top: 20px;\r\n}\r\n\r\ntable {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    margin-top: 10px;\r\n}\r\n\r\nth, td {\r\n    border: 1px solid #ddd;\r\n    padding: 8px;\r\n    text-align: left;\r\n}\r\n\r\nth {\r\n    background-color: #27ca9f;\r\n    color: #fff;\r\n}\r\n\r\ntr:hover {\r\n    background-color: rgba(39, 202, 159, 0.1);\r\n}\r\n\r\n.transaction-icon {\r\n    margin-right: 10px;\r\n    color: #27ca9f;\r\n}\r\n\r\n.noTransactions{\r\n    font-size: 20px;\r\n    margin-left: 15%;\r\n}\r\n\r\n\r\n\r\n@media (max-width: 768px) {\r\n    .financial-overview {\r\n        flex-direction: column; \r\n        align-items: center; \r\n    }\r\n\r\n    .overview-card {\r\n        width: 100%; \r\n        margin-left: 0; \r\n        margin-bottom: 2%;\r\n    }\r\n\r\n    .noTransactions{\r\n        font-size: 20px;\r\n        text-align: center;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
