// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-history-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.transaction-history-card {
    width: 80%;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.heading {
    margin-top: 10px;
    margin-bottom: 20px;
    color: #27ca9f;
}

td.incoming {
    color: green;
}

td.outgoing {
    color: red;
}

.spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

`, "",{"version":3,"sources":["webpack://./src/styles/transaction-history.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,2BAAsB;IAAtB,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,uCAAuC;AAC3C;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".transaction-history-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n}\r\n\r\n.transaction-history-card {\r\n    width: 80%;\r\n    max-width: fit-content;\r\n    padding: 20px;\r\n    margin: 20px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.heading {\r\n    margin-top: 10px;\r\n    margin-bottom: 20px;\r\n    color: #27ca9f;\r\n}\r\n\r\ntd.incoming {\r\n    color: green;\r\n}\r\n\r\ntd.outgoing {\r\n    color: red;\r\n}\r\n\r\n.spinner{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top: 5%;\r\n    margin-bottom: 5%;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
