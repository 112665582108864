import axios from 'axios';
import { store } from '../store.ts';

const ApiClient = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL || '';

    const sessionToken = store.getState().auth.user.sessionToken || '';


    const axiosInstance = axios.create({
        baseURL: BASE_URL,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${sessionToken}`,
        },
    });

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const message =
                error?.response?.data?.message ||
                error.message ||
                'An error occurred while making the request.';
            return Promise.reject({ message });
        }
    );

    return axiosInstance;
};

export default ApiClient;
