/* eslint-disable import/no-anonymous-default-export */
import { AccountNumber, ApiResponse, CreateBeneficiary, Deposit, Send, Withdraw } from "../types";
// @ts-ignore
import ApiClient from "./ApiClient.tsx";

class AccountService {
    depositMoney = async (depositData: Deposit) => {
        try {
            const response: ApiResponse = await ApiClient().post("transactions/deposit", depositData);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Deposit unsuccessful');
            }

            return response.data;
        } catch (error) {
            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    withdrawMoney = async (withdrawData: Withdraw) => {
        try {
            const response: ApiResponse = await ApiClient().post("transactions/withdraw", withdrawData);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Withdrawal unsuccessful');
            }

            return response.data;
        } catch (error) {
            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    sendMoney = async (sendData: Send) => {
        try {
            const response: ApiResponse = await ApiClient().post("transactions/send", sendData);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Transaction sending unsuccessful');
            }

            return response.data;
        } catch (error) {
            throw new Error(error.message || 'Sorry, something went wrong. Please try again later');
        }
    };

    getAccountBalance = async (accountNumber: AccountNumber) => {
        try {
            const response: ApiResponse = await ApiClient().get(`transactions/account-balance/${accountNumber}`);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Account balance not found');
            }

            return response.data;
        } catch (error) {
            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    getUserDepositTransactions = async (userId: number) => {
        try {
            const response: ApiResponse = await ApiClient().get(`transactions/deposit/${userId}`);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Deposit transactions not found');
            }

            return response.data;
        } catch (error) {
            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    getUserWithdrawalTransactions = async (userId: number) => {
        try {
            const response: ApiResponse = await ApiClient().get(`transactions/withdrawal/${userId}`);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Withdrawal transactions not found');
            }

            return response.data;
        } catch (error) {
            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    getUserTransferTransactions = async (userId: number) => {
        try {
            const response: ApiResponse = await ApiClient().get(`transactions/transfer/${userId}`);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Transfer transactions not found');
            }

            return response.data;
        } catch (error) {
            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    getUserIncomingTransactions = async (userId: number) => {
        try {
            const response: ApiResponse = await ApiClient().get(`transactions/incoming/${userId}`);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Incoming transactions not found');
            }

            return response.data;
        } catch (error) {
            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    getUserAccountNumber = async (userId: number) => {
        try {
            const response: ApiResponse = await ApiClient().get(`accounts/userAccounts/${userId}`);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Account number not found');
            }

            return response.data;
        } catch (error) {
            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    getAllAccountNumbers = async () => {
        try {
            const response: ApiResponse = await ApiClient().get(`accounts/allAccounts`);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'No Account numbers found');
            }

            return response.data;
        } catch (error) {
            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    createBeneficiary = async (createBeneficiaryDto: CreateBeneficiary): Promise<any> => {
        try {
            const response: ApiResponse = await ApiClient().post("accounts/add-beneficiary", createBeneficiaryDto);
            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Beneficiary creation unsuccessful');
            }

            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    deleteBeneficiary = async (deleteBeneficiaryDto: { userId: number, beneficiaryId: string }): Promise<any> => {
        try {
            const response: ApiResponse = await ApiClient().delete("accounts/delete-beneficiary", { data: deleteBeneficiaryDto });

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Beneficiary deletion unsuccessful');
            }

            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    getBeneficiaries = async (userId: number): Promise<any> => {
        try {
            const response: ApiResponse = await ApiClient().get(`accounts/beneficiaries/${userId}`);
            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Beneficiaries not found');
            }

            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    }
};

export default new AccountService();
