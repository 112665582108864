import React, { useEffect, useState } from 'react';
import Header from '../components/header.tsx';
import Sidebar from '../components/sidebar.tsx';
import Footer from '../components/footer.tsx';
import FinancialOverview from '../components/FinancialOverview.tsx';
import '../styles/home.css'
import { Col, Row } from 'reactstrap';
import FinancialGraph from '../components/FinancialGraphOverview.tsx';
import RecentTransactions from '../components/recentTransactions.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/auth.slice.ts';
import { setProfile } from '../redux/slices/profile.slice.ts';
import AccountsService from '../api/AccountsService.tsx';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';


interface Props {

}

const Home = (props: Props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(selectAuth);
    const { userId } = user;
    const [accountBalance, setAccountBalance] = useState(0);
    const [income, setIncome] = useState(0);
    const [expenses, setExpenses] = useState(0);
    const [accountType, setAccountType] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [deposits, setDeposits] = useState(0);
    const [withdrawals, setWithdrawals] = useState(0);
    const [outgoingTransfers, setOutgoingTransfers] = useState(0);
    const [depositTransactions, setDepositTransactions] = useState([]);
    const [withdrawalTransactions, setWithdrawalTransactions] = useState([]);
    const [transferTransactions, setTransferTransactions] = useState([]);


    const allTransactions: any = [...depositTransactions, ...transferTransactions, ...withdrawalTransactions];
    const sortedTransactions = allTransactions.sort((a: any, b: any) => new Date(a.created_at) - new Date(b.created_at));
    const formattedTransactions = sortedTransactions.map((transaction: { type: string; amount: any; created_at: string; }) => {
        const type = transaction.type === 'DEPOSIT' ? 'Income' : 'Expense';
        const amount = transaction.amount;
        const date = transaction.created_at.split('T')[0];

        return { type, amount, date };
    });



    useEffect(() => {
        const getAccountBalance = async () => {
            try {
                const response = await AccountsService.getUserAccountNumber(userId);
                const accountNumber = response?.data?.accountNumber;
                const balance = response?.data?.balance;
                const accountType = response?.data?.accountType;
                setAccountBalance(balance);
                setAccountType(accountType);
                setAccountNumber(accountNumber);
            } catch (error) {
                toast.error('An Error occurred while fetching your account balance, please try again later');
            }

        }

        const getUserDeposits = async () => {
            try {
                const response = await AccountsService.getUserDepositTransactions(userId);
                const deposits = response?.depositTransactions || [];
                setDepositTransactions(deposits);
                const totalAmount = deposits.reduce((acc: any, deposit: { amount: any; }) => acc + deposit.amount, 0);
                setDeposits(totalAmount);
            } catch (error) {
                toast.error('An Error occurred while fetching your deposits, please try again later');
            }
        };


        const getUserTransfers = async () => {
            try {
                const response = await AccountsService.getUserTransferTransactions(userId);
                const transfers = response?.transferTransactions || [];
                setTransferTransactions(transfers);
                const totalAmount = transfers.reduce((acc: any, transfers: { amount: any; }) => acc + transfers.amount, 0);
                setOutgoingTransfers(totalAmount);
            } catch (error) {
                toast.error('An Error occurred while fetching your transfers, please try again later');
            }
        };



        const getUserWithdrawals = async () => {
            try {
                const response = await AccountsService.getUserWithdrawalTransactions(userId);
                const withdrawals = response?.withdrawalTransactions || [];
                setWithdrawalTransactions(withdrawals);
                const totalAmount = withdrawals.reduce((acc: any, withdrawals: { amount: any; }) => acc + withdrawals.amount, 0);
                setWithdrawals(totalAmount);
            } catch (error) {
                toast.error('An Error occurred while fetching your withdrawals, please try again later');
            }
        };



        const icomeStream = deposits;
        const expenseStream = withdrawals + outgoingTransfers;
        setIncome(icomeStream);
        setExpenses(expenseStream);



        getAccountBalance();
        getUserDeposits();
        getUserTransfers();
        getUserWithdrawals();
        dispatch(setProfile(
            {
                accountBalance,
                accountNumber,
            }
        ))
    }, [accountBalance, accountNumber, deposits, dispatch, outgoingTransfers, userId, withdrawals]);


    return (
        <>
            <Helmet>
                <title>Home - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <div className="rootContainer">
                <Header />
                <div className={`container ${window.innerWidth <= 768 ? 'hide-sidebar' : ''}`}>
                    <Row>
                        <Col md={12} sm={12} lg={12} xl={12}>
                            <FinancialOverview balance={accountBalance} income={income} expenses={expenses} accountType={accountType} accountNumber={accountNumber} />
                        </Col>
                        <Row>
                            <Col md={8} sm={12} lg={8} xl={8}>
                                <FinancialGraph />
                            </Col>
                            <Col md={4} sm={12} lg={4} xl={4}>
                                <RecentTransactions transactions={formattedTransactions} />
                            </Col>
                        </Row>
                    </Row>
                </div>
                <Sidebar />
            </div>

            {/*<Footer />*/}

        </>
    );
}

export default Home;

