import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Form,
    Input,
    Button,
    Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/signup.css';
import logo from '../images/logo.svg';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../api/AuthService.tsx';
import { Helmet } from 'react-helmet-async';

const SignIn: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const fullText = "Welcome back.";
    const [error, setError] = useState('');

    useEffect(() => {
        let index = 0;
        const intervalId = setInterval(() => {
            setText(fullText.slice(0, index));
            index++;
            if (index > fullText.length) {
                clearInterval(intervalId);
            }
        }, 100);
    }, []);

    const handleLogin = async () => {
        try {
            if (!email || !password) {
                setError('Please fill in all fields.');
                return;
            }
            setLoading(true);
            await AuthService.login({ email, password });
            navigate('/otp', { state: { email } });
        } catch (err) {
            setError(err.message);
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleOauth = async () => {
        navigate('/google/login');
    }


    // const handleTogglePasswordVisibility = () => {
    //     setShowPassword(!showPassword);
    // };

    const handleInputChange = () => {
        setError('');
    };

    return (
        <div className="signup-page">
            <Helmet>
                <title>Sign In - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Card className="signup-card">
                <CardBody>
                    <img src={logo} alt='logo' className='logo' />
                    <h4 className="heading">{text}</h4>
                    <div className='formGroup'>
                        <Form>
                            <Input
                                type="email"
                                placeholder="Enter your email address"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value); handleInputChange(); }}
                                className="input"
                            />
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => { setPassword(e.target.value); handleInputChange(); }}
                                className="input"
                            />

                            {error && <div style={{ color: "#ff0000" }}>{error}</div>}
                            {loading ? (<div className='spinner'>
                                <Spinner
                                    color="primary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="secondary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="success"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="danger"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="warning"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="info"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="light"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="dark"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>) : (
                                <>
                                    <Button
                                        className='buttonSubmit'
                                        style={{ backgroundColor: "#27ca9f" }}
                                        onClick={handleLogin}
                                        disabled={loading}
                                    >
                                        Login
                                    </Button>
                                    <div className="orText mt-2 mb-2">- Or -</div>
                                    <Button className='googleButton' onClick={handleGoogleOauth}>
                                        <FontAwesomeIcon icon={faGoogle} className="googleIcon" />
                                        Sign in with Google
                                    </Button>
                                </>
                            )}
                        </Form>
                    </div>
                    <div className="text-center mt-3">
                        <Link to='/forgot-password' className="text-underline">
                            Forgot Password?
                        </Link>
                        <span className="mx-2">-</span>
                        <Link to='/signup' className="text-underline">
                            Don't have an account?
                        </Link>
                    </div>
                </CardBody>
            </Card>
            <div className="signin-image" style={{ width: '50%', height: '100%' }}></div>
            <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default SignIn;
