import React, {useEffect, useState} from 'react';
import Navbar from "../components/navbar.tsx";
import Footer from "../components/footer.tsx";
import { Helmet } from 'react-helmet-async';
import '../styles/terms.css';
import MobileNavbar from "../components/mobileNavBar.tsx";

const TermsConditions: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

return (
        <>
        <Helmet>
            <title>Terms & Conditions - MattressBank</title>
            <meta name='description' content='View our terms and conditions for using MattressBank services.'/>
        </Helmet>
            {isMobile ? <MobileNavbar/> : <Navbar/>}
            <div className="terms-container">
                <h1>Terms and Conditions</h1>
                <h2>1. Why You Should Read These Terms and Conditions</h2>
                <p>1.1 This agreement covers the terms and conditions on which we provide our services to you.</p>
                <p>1.2 <strong>Why you should read them:</strong> Please read these terms and conditions carefully
                    before you start to use our Services. These terms and conditions tell you who we are, how we will
                    provide the Services to you, how these terms and conditions may be changed or ended, what to do if
                    there is a problem, and other important information. If you think that there is a mistake in these
                    terms and conditions or require any changes, please contact us to discuss.</p>
                <p>1.3 <strong>Other additional documents which apply to you:</strong> This terms and conditions refers
                    to the following additional documents, which also apply to your use of our Services:</p>
                <ul>
                    <li>(a) Our Privacy Policy, which sets out the terms on which we process any personal data we
                        collect about you, or that you provide to us. By using our Services, you consent to such
                        processing and you promise that all data provided by you is accurate.
                    </li>
                    <li>(b) Our Frequently Asked Questions ("FAQ") which provides answers to common customer
                        questions.
                    </li>
                    <li>(c) In order to receive some of our Services, you may be asked to agree to additional terms and
                        conditions which we will notify you about at the relevant time.
                    </li>
                </ul>
                <p>1.4 <strong>Future changes to these terms and conditions:</strong> All changes set out on our Website
                    at the time you sign-up for our Services are incorporated into these terms and conditions. These
                    terms and conditions may be changed from time to time.</p>
                <p>1.5 <strong>You accept these terms and conditions:</strong> By visiting our Website and/or using our
                    Services (including downloading and using our App, or via the API, a social media platform or other
                    authorized third party), you confirm that you accept and agree to these terms and conditions. If you
                    do not agree, please do not use our Services.</p>
                <p>1.6 <strong>Where to get a copy of these terms and conditions:</strong> You can always see the most
                    current version of these terms and conditions on our Website. If you want a paper copy of these
                    terms and conditions, please contact Customer Support.</p>
                <h2>3. Glossary</h2>
                <p>In this terms and conditions:</p>
                <dl>
                    <dt>API</dt>
                    <dd>means the application programming interface provided by matressbank.</dd>
                    <dt>App</dt>
                    <dd>means the mobile application software, the data supplied with the software, and the associated
                        media.
                    </dd>
                    <dt>Business Day</dt>
                    <dd>means a day other than a Saturday, Sunday or a public holiday.</dd>
                    <dt>Currency Account</dt>
                    <dd>means the representation of your funds held in different currencies in your Multi Currency
                        Account.
                    </dd>
                    <dt>Multi Currency Account</dt>
                    <dd>means your individual payment account offered by matressbank that enables you to hold funds in
                        different currencies, which may be represented in multiple Currency Accounts, and initiate
                        payments from those Currency Accounts.
                    </dd>
                    <dt>Personal Account</dt>
                    <dd>is the account that allows individuals to use our Services, including any activities related to
                        a Multi Currency Account. Each individual can only have one Personal Account in their
                        matressbank Account.
                    </dd>
                    <dt>Services</dt>
                    <dd>means all products, services, content, features, technologies or functions offered by us and all
                        related websites, applications (including the App), and services (including the Website and
                        API).
                    </dd>
                    <dt>Source Currency</dt>
                    <dd>means the currency which you hold and/or fund your payment order with.</dd>
                    <dt>Target Currency</dt>
                    <dd>means the currency which your recipient will receive.</dd>
                    <dt>matressbank Account</dt>
                    <dd>is the umbrella term given to your Personal Account.</dd>
                    <dt>Website</dt>
                    <dd>means any webpage, including but not limited to www.matressbank.com, where we provide the
                        Services to you.
                    </dd>
                </dl>

                <h2>4. matressbank App and API</h2>
                <p>4.1 <strong>App subject to these terms and conditions and the App store Rules.</strong> We license
                    the use of the App to you on the basis of these terms and conditions and subject to any rules and
                    policies applied by any appstore provider or operator whose sites are located at App Store and
                    Google Play. We do not sell the App to you. We remain the owners of the App at all times.</p>
                <p>4.2 <strong>App updates.</strong> From time to time, updates to the App may be issued through the App
                    Store or Google Play. Depending on the update, you may not be able to use our Services via the App
                    until you have downloaded the latest version of the App and accepted any new terms.</p>
                <p>4.3 <strong>Your right to use the App and the API.</strong> In consideration of you agreeing to abide
                    by the terms of these terms and conditions, we grant you a non-transferable, non-exclusive license
                    to use the App on your device and the API subject to these terms and conditions. We reserve all
                    other rights.</p>

                <h2>5. Who Are We and How to Contact Us</h2>
                <p>5.1 <strong>Our company information.</strong> matressbank is a company incorporated under the law of
                    the Netherlands ("matressbank", "we", "us", or "our" as applicable).</p>
                <p>5.2 <strong>Our registered office</strong> is matressbank Marconistraat 16, 3029 AK Rotterdam, the
                    Netherlands.</p>
                <p>5.3 <strong>How to contact us.</strong> You can contact us by email, web chat, or telephone. Our
                    contact details are provided on the "Contact" page of our Website.</p>

                <h2>6. Who Can Use Our Services</h2>
                <p>6.1 <strong>You must be 18 years or over.</strong> If you are an individual, you must be 18 years or
                    older to use our Services and by opening a matressbank Account you declare that you are 18 years or
                    older. We may ask you at any time to show proof of your age.</p>
                <p>6.2 <strong>Your use of the matressbank Account must not violate any applicable laws.</strong> You
                    commit to us that your opening and/or using of a matressbank Account does not violate any laws
                    applicable to you. You take responsibility for any consequences of your breach of this section.</p>
                <h2>7. Your Multi Currency Account</h2>
                <p>7.1 About your Multi Currency Account</p>
                <ul>
                    <li>(a) Your Multi Currency Account allows you to hold, send or receive funds.</li>
                    <li>(b) The funds held in your Multi Currency Account do not expire other than when your account is
                        closed.
                    </li>
                    <li>(c) The funds held on your Multi Currency Account will not earn any interest.</li>
                    <li>(d) You may hold your funds in any currencies which we support from time to time.</li>
                    <li>(e) You may withdraw money from your Multi Currency Account at any time, subject to certain
                        conditions.
                    </li>
                    <li>(f) Certain limits may be placed on your Multi Currency Account depending on your country of
                        residence, verification checks or other legal considerations. Please contact us if you have any
                        questions about these limits.
                    </li>
                    <li>(g) The funds held on your Multi Currency Account belongs to the person or legal entity which is
                        registered as the matressbank Account holder.
                    </li>
                    <li>(h) Unless you have our consent in writing, you must not allow anyone to operate your Multi
                        Currency
                        Account on your behalf.
                    </li>
                </ul>
                <p>7.2 Your Multi Currency Account is a payment account and is not a bank (deposit) account. You
                    acknowledge
                    that the Netherlands Guarantee Scheme does not apply to your matressbank Account. However, we follow
                    the
                    requirements under the EU Payment Services Directive 2015/2366 (PSD2) and the PIL which are designed
                    to
                    ensure the safety of funds held in payment accounts like your matressbank Account. For further
                    information on how we look after your money, please visit FAQ.</p>

                <h2>8. Getting Started</h2>
                <p>8.1 Open a matressbank Account. To start using our Services, you must open a matressbank Account and
                    provide your details as prompted.</p>
                <p>8.2 Information must be accurate. All information you provide to us must be complete, accurate and
                    truthful at all times. You must update this information whenever it changes. We cannot be
                    responsible
                    for any financial loss arising out of your failure to do so. We may ask you at any time to confirm
                    the
                    accuracy of your information and/or to provide additional supporting documents.</p>
                <p>8.3 Transacting on your own account. All activities under a matressbank Account shall be deemed as
                    activities carried out by the registered user. You shall only use the Services to transact on your
                    own
                    account and not on behalf of any other person or entity.</p>
                <p>8.4 One account per person or entity. You may only open one Personal Account and one or more Business
                    Accounts. While each account might be linked to one matressbank Account, each person or entity (each
                    a
                    “matressbank Account holder”) is individually bound to the terms and conditions applicable to the
                    address in their account as provided here. matressbank may refuse the creation of duplicate accounts
                    for
                    the same user. Where duplicate accounts are detected, matressbank may close or merge these duplicate
                    accounts at its sole discretion.</p>

                <h2>9. Getting to Know You</h2>
                <p>9.1 We are required by law to carry out all necessary security and customer due diligence checks on
                    you
                    (including any parties involved in your transaction, for example, your recipient) in order to
                    provide
                    any Services to you. You agree to comply with any request from us for further information and
                    provide
                    such information in a format acceptable to us. In addition, you agree that we may make, directly or
                    through any third party, any inquiries we consider necessary to validate the information you
                    provided to
                    us, including checking commercial databases or credit reports. You authorize us to obtain one or
                    more of
                    your credit reports, from time to time, to establish, update, or renew your matressbank Account with
                    us
                    or in the event of a dispute relating to this terms and conditions and activity under your
                    matressbank
                    Account.</p>

                <h2>10. Keep Your Matressbank Account Safe</h2>
                <p>10.1 What to Do</p>
                <ul>
                    <li>(a) Change your password regularly and ensure that it isn’t reused across other online accounts.
                    </li>
                    <li>(b) Contact Customer Support if anyone asks for your matressbank password.</li>
                    <li>(c) Always follow recommended password management practice, for example: <a
                        href="https://support.google.com/accounts/answer/32040?hl=en">Google's Advice</a>.
                    </li>
                    <li>(d) Set up 2-step authentication where prompted (for further instructions please refer to our
                        FAQ).
                    </li>
                    <li>(e) Keep your email account secure. You may reset your matressbank Account password using your
                        email
                        address. Let Customer Support know immediately if your email address becomes compromised.
                    </li>
                </ul>
                <p>10.2 What NOT to Do</p>
                <ul>
                    <li>(a) Disclose your matressbank Account password or your customer reference number (which starts
                        with
                        the letter P followed by a series of numbers). Keep them safe.
                    </li>
                    <li>(b) Let anyone access your matressbank Account or watch you accessing it.</li>
                    <li>(c) Use any functionality that allows your login details or passwords to be stored by the
                        computer
                        or browser you are using or to be cached or other matressbank recorded.
                    </li>
                    <li>(d) Do anything which may in any way avoid or compromise the 2-step authentication process.</li>
                </ul>
                <p>10.2 Contact us if you suspect your matressbank Account has been compromised. If you suspect your
                    matressbank Account or other security credentials are stolen, lost, used without your authorisation
                    or
                    otherwise compromised, you must contact Customer Support immediately, you are also advised to change
                    your password. Any undue delays in notifying us may affect the security of your matressbank Account
                    and
                    also result in you being responsible for financial losses.</p>
                <p>10.3 Authorising third parties. You may authorize third parties to access your matressbank Account to
                    provide their services to you, including authorizing them to initiate payments from your matressbank
                    Account. You acknowledge that if you authorize a third party to access your matressbank Account, we
                    may
                    disclose certain information about your matressbank Account to this third party. We are not
                    responsible
                    for any such third party’s use of your matressbank Account or any information in your matressbank
                    Account. Granting permission to a third party does not relieve you of your responsibilities under
                    these
                    terms and conditions, including notifying us if your matressbank Account has been compromised or if
                    a
                    transaction is incorrect or unauthorized.</p>
                <h2>11. Uploading Money into Your Matressbank Account</h2>
                <p>11.1 <strong>How to upload money into your Multi Currency Account:</strong> To upload money, you need
                    to log in to your matressbank Account and follow the steps as they appear on screen. We are not
                    responsible for the money you have uploaded until we have received them. For clarity, in an upload
                    transaction, we are the recipient of funds and not the payment services provider.</p>
                <p>11.2 <strong>Paying Methods:</strong> You may be presented with one or more methods of upload, for
                    example, bank transfer, credit cards, or debit cards (in this terms and conditions, we will call
                    these methods “Paying Methods”). The number of Paying Methods made available to you will depend on a
                    number of factors including where you live and your verification status with us. Paying Methods are
                    not part of our Services, they are services provided by third parties, for example, the card
                    provider which issued you with your credit/debit card. We cannot guarantee the use of any particular
                    Paying Method and may change or stop offering a Payment Method at any time without notice to you.
                </p>
                <p>11.3 <strong>Payment instrument must be in your name:</strong> Any payment instrument (for example,
                    the credit card or debit card) you use with your chosen Payment Method must be in your name.</p>
                <p>11.4 <strong>Chargebacks on your payment instrument:</strong> If you selected a Paying Method which
                    gives you chargeback rights (for example in relation to your credit card), you promise that you will
                    only exercise this chargeback right if:</p>
                <ul>
                    <li>(a) we have breached this terms and conditions; or</li>
                    <li>(b) there was an unauthorised use of your payment instrument.</li>
                </ul>
                <p>You promise that you will not exercise your chargeback right for reasons which we are not
                    responsible, including a dispute with your recipient or if there are insufficient funds in your
                    payment instrument. If we need to investigate or take any actions in connection with a chargeback
                    raised by you, we may charge you for our costs in doing so and may deduct such an amount from your
                    Multi Currency Account.</p>
                <p>11.5 <strong>Upload limits on your Multi Currency Account:</strong> For legal and security reasons,
                    we impose limits on how much you can upload into your Multi Currency Account.</p>
                <p>11.6 <strong>When we will credit your Multi Currency Account:</strong> We will credit your Multi
                    Currency Account once we have received your money. For some Paying Methods such as credit or debit
                    card, we will credit the money to your Multi Currency Account as soon as possible subject to our
                    right of reversal. This means if the actual amount you intended to upload does not reach us within a
                    reasonable time, we may deduct such amount from your Multi Currency Account. If you do not have
                    enough money in your Multi Currency Account for this purpose, we can demand repayment from you using
                    other methods.</p>

                <h2>12. Sending Money</h2>
                <p>12.1 <strong>Setting up your payment order:</strong> You must set up your payment order from your
                    matressbank Account. Your order may either be:</p>
                <ul>
                    <li>(a) a "Fixed Source Order" which is a payment order where you indicate that you wish to send and
                        convert a fixed amount of Source Currency to your recipient who will receive the converted
                        amount in the Target Currency; or
                    </li>
                    <li>(b) a "Fixed Target Order" which is a transfer where you indicate that you wish to send and
                        convert a fixed amount of Target Currency to your recipient from the Source Currency you pay
                        into matressbank.
                    </li>
                </ul>
                <p>You can only set up a Fixed Target Order for certain Source Currencies, you can find a list of these
                    Source Currencies on our FAQ.</p>
                <p>12.2 <strong>Information you need to provide to set up a payment order:</strong> To set up a payment
                    order via your matressbank Account, you need to provide certain information to us including the full
                    name of your recipient, your recipient’s bank account details or their matressbank Account details,
                    and the amount to be transferred.</p>
                <p>12.3 <strong>Payment order limits:</strong> We may place limits on the amount you may send per
                    transfer. For more information on the applicable limits, please visit our FAQ.</p>
                <p>12.4 <strong>When is your payment order received:</strong> If your payment order is received by us
                    after 5pm on a Business Day or not on a Business Day, your payment order will be deemed received on
                    the following Business Day.</p>
                <p>12.5 <strong>What happens after you have submitted your payment order:</strong> Once we have received
                    your payment order, we will display it under the Activity section of your matressbank account. Each
                    payment order is given a unique transaction number which you can find there. You should quote this
                    number when communicating with us about a particular payment order.</p>
                <p>12.6 <strong>You need to provide us with sufficient funds before we can process your payment
                    order:</strong> We will only process your payment order if we hold or have received sufficient
                    cleared funds in your matressbank Account. It is your responsibility to fund your payment order in a
                    timely manner. We cannot be responsible for the time it takes for the money to be sent to us by your
                    bank or payment service provider.</p>
                <p>12.7 <strong>Verification checks may increase the time for processing your payment order:</strong> We
                    carry out verification checks, and these checks may increase the time it takes to process your
                    payment order. We cannot be responsible for any delays as a result of carrying out those checks.</p>
                <p>12.8 <strong>Completion time of your payment order:</strong> The estimated completion time of your
                    payment order is notified to you when you complete the setup of your payment order. You may also
                    find further information about the completion time in the FAQ section of our Website, please refer
                    to the applicable currencies in your payment order.</p>
                <p>12.9 <strong>We will use reasonable efforts to ensure funds arrive at your recipient’s account within
                    the notified timeframe:</strong> We will use reasonable efforts to ensure that the funds arrive in
                    the recipient’s bank account or payment account within the timelines notified to you or other
                    matressbank specified in our FAQ section. We do not have any control over the time it may take for
                    the recipient’s bank or payment provider to credit and make available funds to the recipient.</p>
                <p>12.10 <strong>Refusal of your payment order:</strong> If we are unable to complete your payment
                    order, we will let you know and, if possible, the reasons for the refusal and an explanation of how
                    to correct any factual errors. However, we are not required to notify you if such notification would
                    be unlawful.</p>
                <p>12.11 <strong>You may cancel your payment order before your funds are converted:</strong> You may
                    cancel your payment order by following the instructions set out in our FAQ. You cannot cancel your
                    payment order once your funds have been converted into the Target Currency you requested.</p>
                <p>12.12 <strong>You must ensure the information you provide to us is correct:</strong> You must make
                    sure that the information you provide when setting up a payment order is accurate. If we have
                    processed your order in accordance with the information you have provided to us, it will be
                    considered correctly completed even if you have made a mistake.</p>
                <p>12.13 <strong>What happens if you provide us with incorrect information?</strong> If you provide
                    incorrect information with your payment order, we will use reasonable efforts to recover the funds
                    for you, and may need to charge you a fee for that.</p>
                <p>12.14 <strong>When will I be notified of my next scheduled transfer:</strong> If you have scheduled a
                    transfer in advance then we will notify you 24 hours before your upcoming transfer, setting out the
                    total fees and the estimated ‘live’ exchange rate for that transfer. By scheduling a transfer, you
                    agree to matressbank sending the funds using the live exchange rate at any time on the scheduled
                    date. If you have opted in to receiving emails, we will send you a transfer receipt after
                    successfully sending your scheduled transfer. For more information on scheduled transfers, see our
                    Help Centre. </p>
                <h2>13. Exchange Rates</h2>
                <p>13.1 <strong>The applicable exchange rate.</strong> We will let you know the exchange rate:</p>
                <ul>
                    <li>(a) when you place your payment order, if it is a guaranteed rate payment order; or</li>
                    <li>(b) when we have received your payment, if it is a non-guaranteed rate payment order.</li>
                </ul>
                <p>13.2 <strong>Exchange rate:</strong> When we refer to an exchange rate in this terms and conditions,
                    it means the exchange rate at the relevant time for the relevant currency pair (for example, EUR,
                    USD to GBP) that is offered by matressbank, which is typically provided by a reference rate
                    provider. We may change our reference rate provider from time to time without notice to you.</p>
                <p>(b) For some currencies, we do not use the mid-market exchange rate, including where we are required
                    to use a different reference rate for the exchange rate for your currency pair. For example, for
                    currency conversions to Nigeria (NGN), we are required to use the rate set by the Central Bank of
                    Nigeria. For these currencies we will notify you of the exchange rate offered by matressbank when
                    you place your payment order.</p>
                <p>13.3 <strong>Guaranteed rates.</strong> We will notify you of the guaranteed rate and the guaranteed
                    time period (the "Guaranteed Period") when you create your Money Transfer. Guaranteed Periods are
                    subject to the following conditions:</p>
                <ul>
                    <li>(a) The Guaranteed Period may be extended if your Money Transfer is created over the weekend or
                        public holiday.
                    </li>
                    <li>(b) We must receive sufficient funds from you during the Guaranteed Period in order to convert
                        your money at the guaranteed rate. You will see when we have received your money by logging into
                        your matressbank Account.
                    </li>
                    <li>(c) If we receive your funds after the Guaranteed Period, we will not be able to convert your
                        money using the guaranteed rate and your transfer will become a non-guaranteed rate transfer.
                        Accordingly, we may convert your money at the applicable exchange rate at the time we receive
                        your money or we will email you and ask you if you want to proceed with your transfer at the new
                        exchange rate.
                    </li>
                    <li>(d) If the relevant exchange rate (as provided by our reference exchange rate provider) changes
                        by 5% or more during the Guaranteed Period, we can, at our option, suspend your transfer for a
                        reasonable period of time or cancel your transfer and refund the money to you.
                    </li>
                    <li>(e) We may change these guaranteed rate conditions or suspend the guarantee rate feature at any
                        time.
                    </li>
                </ul>
                <p>13.4 <strong>Auto Conversion.</strong> For certain currencies, you may be able to set one or more
                    conversion orders to be automatically executed once the exchange rate you pick is offered by our
                    Services (an “auto conversion order”). There may be limits to the number of auto conversion orders
                    you are able to set up, as well as the amounts of money you can schedule to convert. matressbank
                    does not guarantee that it will be able to execute your auto conversion order in all circumstances.
                    The auto conversion feature is to set up a pre-authorized remittance payment transaction and is not
                    a forward, derivative, or other financial product or device. If you do not fund your pre-authorized
                    transfer it will be canceled.</p>
                <p>13.5 <strong>We are not a currency trading platform.</strong> matressbank is not a currency trading
                    platform, and accordingly, you should not use our Services, including the matressbank Account or the
                    auto conversion order function for this purpose (including creating multiple auto conversion orders
                    or a series of Money Transfers without the intention of completing them or for seeking to profit
                    from FX trading). If we detect that you are using our Services for this purpose, we may, at our sole
                    discretion, set a limit on the number of auto conversion orders you may create, cancel your orders,
                    set a limit on the amount of money you can convert or transfer in one or more currencies or in the
                    same currency, restrict your ability to use this or other features, or suspend or close your
                    matressbank Account and disgorge your gains.</p>

                <h2>14. Receiving Money</h2>
                <p>14.1 <strong>You can receive money into your Multi Currency Account.</strong> In certain currencies,
                    we may provide to you local account details that you can use to send money directly to your Currency
                    Account using Pay-In Methods which we support from time to time or provide to third parties to send
                    money to you (“Account Details”). If you request Account Details, these are linked to your Multi
                    Currency Account which is a single payment account. Account Details therefore don't reflect separate
                    payment accounts. They are only a way to make a Pay-in or receive money into your Multi Currency
                    Account. Your Multi Currency Account is held solely in the EEA by matressbank Europe SA.</p>
                <p>14.2 <strong>The money received is shown in your Multi Currency Account.</strong> Any money you
                    receive into your Multi Currency Account will be recorded in the transaction history section of your
                    Multi Currency Account. You should check the incoming funds in your Multi Currency Account against
                    your own records regularly and let us know if there are any irregularities.</p>
                <p>14.3 <strong>The money received may be subject to reversal.</strong> You acknowledge that the money
                    received in your Multi Currency Account ("Received Amount") may be subject to reversal and you agree
                    that we may deduct the Received Amount from your Multi Currency Account if it was reversed by the
                    person who paid you the Received Amount or any relevant payment services provider.</p>
                <p>14.4 <strong>Sending money using an email address.</strong> If you send money to a person using an
                    email address which is not registered with us, the money will not be credited until the intended
                    recipient has claimed the money following the steps we have set out for them. Until then, there is
                    no relationship between us and the intended recipient and the money continues to belong to you. We
                    will refund the money to you if the intended recipient does not claim the money or if they have
                    failed our customer checks within a reasonable time period as determined by us.</p>

                <h2>15. Maintaining your Matressbank Account</h2>
                <p>15.1 <strong>Transaction history is displayed on your Matressbank Account.</strong> All your
                    transactions (including your current balance, money you have uploaded, received, sent, and/or
                    withdrawn) are recorded in the transaction history section of your Matressbank Account. You may
                    access this information after you log in to your Matressbank Account. We have allocated a reference
                    number to each transaction; you should quote this reference number when communicating with us about
                    a particular transaction.</p>
                <p>15.2 <strong>Check your Matressbank Account regularly.</strong> You must check your Matressbank
                    Account regularly and carefully and contact us immediately if you don’t recognise a transaction or
                    think we have made a payment incorrectly. You must tell us about any unauthorised or incorrectly
                    executed transactions immediately, but no later than thirteen (13) months from the transaction;
                    otherwise, you may not be entitled to have any errors corrected.</p>
                <p>15.3 <strong>You accept the risks of holding your funds in multiple currencies.</strong> You agree
                    and accept all the risks associated with maintaining Currency Accounts in multiple currencies
                    including any risks associated with fluctuations in the relevant exchange rates over time. You agree
                    that you will not use our Services for speculative trading.</p>
                <p>15.4 <strong>No negative balance in your Multi Currency Account.</strong> You promise to always have
                    a zero or positive balance in your Multi Currency Account. If your Multi Currency Account goes into
                    a negative balance as a result of a chargeback, reversal of a transaction, deduction of fees, or any
                    other action carried by you, you promise to repay the negative balance immediately without any
                    notice from us. We may send you reminders or take other reasonable actions to recover the negative
                    balance from you, for example, we may use a debt collection service or take further legal actions.
                    We will charge you for any costs we may incur as a result of these additional collection efforts.
                </p>
                <p>15.5 <strong>Taxes.</strong> You are responsible for any taxes which may be applicable to payments
                    you make or receive, and it is your responsibility to collect, report, and pay the correct tax to
                    the appropriate tax authority.</p>

                <h2>16. Withdrawing from your Multi Currency Account</h2>
                <p>16.1 <strong>You can request to withdraw your money.</strong> After you log in to your Multi Currency
                    Account, you may request all or part of your money held in your Multi Currency Account to be
                    withdrawn. Press "send money" and follow the steps as prompted on screen. We will charge you a fee
                    for each withdrawal request where this is permissible by law, we will let you know the exact amount
                    when you submit your request. You can also find out more information about the fees we charge on the
                    Pricing page.</p>
                <p>16.2 <strong>Payout Methods available to you.</strong> You may be presented with one or more methods
                    of withdrawal (in this terms and conditions, we will call these methods "Payout Methods"). The
                    number of Payout Methods made available to you will depend on a number of factors including where
                    you live and your verification status with us. We cannot guarantee the use of any particular Payout
                    Method and may change or stop offering a Payout Method at any time without notice to you, but we
                    will ensure that you will always have at least one Payout Method available to you.</p>
                <p>16.3 <strong>You must provide correct information to us.</strong> When setting up your withdrawal
                    request, you must ensure that the information you provide is correct and complete. We will not be
                    responsible for money sent to the wrong recipient as a result of incorrect information provided by
                    you. If you have provided wrong information to us, you may ask us to assist you in recovering the
                    money, but we cannot guarantee that such efforts will be successful.</p>
                <p>16.4 <strong>Your withdrawal request is subject to limits.</strong> You agree that your Multi
                    Currency Account is subject to withdrawal limits. If your withdrawal request exceeds the current
                    limit, we may decline your request and require you to provide additional documents to us so that we
                    can carry out additional checks before allowing the money to be withdrawn.</p>

                <h2>17. How much will you pay?</h2>
                <p>17.1 <strong>You must pay our fees.</strong> You must pay the fees in connection with the use of our
                    Services. We will not process your transaction until we have received the fees from you.</p>
                <p>17.2 <strong>You can see our fee structure on the Pricing page.</strong> We will let you know the
                    exact amount payable by you when you set up your order. You can see our fee structure on the
                    "Pricing" page. For clarity, the fees applicable to you as set out on the "Pricing" page forms part
                    of these terms and conditions which may be subject to change as set out in section 26.</p>
                <p>17.3 <strong>We can make deductions from your Multi Currency Account.</strong> You agree that we are
                    authorized to deduct our fees, any applicable reversal amounts, and/or any amounts you owe us from
                    your Multi Currency Account. If you don’t have enough money in your Multi Currency Account to cover
                    these amounts, we may refuse to execute the relevant transaction or provide any Services to you.</p>
                <p>17.4 <strong>We may charge you a variable fee during periods of potential heightened volatility or
                    unpredictability in the foreign exchange market,</strong> as determined by matressbank. We will let
                    you know if this fee is applicable when you set up your order, for more information see here.</p>

                <h2>18. Currency Conversion</h2>
                <p>18.1 <strong>You may convert the money held in one currency in your Multi Currency Account into other
                    currencies we support from time to time.</strong> You can only perform a conversion in respect of
                    funds that you already hold in your Multi Currency Account. A conversion fee will apply when we
                    perform a currency conversion, for more information, see here.</p>
                <p>18.2 <strong>Refusing a currency conversion order.</strong> We reserve the right in our sole
                    discretion to refuse any currency conversion order. Reasons for refusal may include but are not
                    limited to incorrect information about the recipient, insufficient available funds, or where we
                    believe you may have violated these terms and conditions, including we believe you are attempting to
                    engage in currency trading or other trading for purposes not permitted by these terms and
                    conditions. We will endeavor to notify you of any refusal, using the contact information in your
                    Multi Currency Account, stating (where possible) the reasons for such refusal and explaining how to
                    correct any errors. However, we will not notify you if such notification may be unlawful.</p>

                <h2>19. Closing your Multi Currency Account and/or matressbank Account</h2>
                <p>19.1 <strong>You may close your Multi Currency Account and/or matressbank Account at any
                    time.</strong> You may end this terms and conditions and close your Multi Currency Account and/or
                    matressbank Account at any time by contacting our Customer Support.</p>
                <p>19.2 <strong>You should withdraw your money within a reasonable time.</strong> At the time of
                    closure, if you still have money in your Multi Currency Account, you must withdraw your money within
                    a reasonable period of time by following the steps described in section 16. After a reasonable
                    period of time, you will no longer have access to your Multi Currency Account, but you can still
                    withdraw your money by contacting Customer Support. You have the right to do this for a period of
                    six (6) years from the date your Multi Currency Account is closed.</p>
                <p>19.3 <strong>You must not close your matressbank Account to avoid an investigation.</strong> You must
                    not close your Multi Currency Account and/or matressbank Account to avoid an investigation. If you
                    attempt to close your Multi Currency Account and/or matressbank Account during an investigation, we
                    may hold your money until the investigation is fully completed in order to protect our or a third
                    party’s interest.</p>
                <p>19.4 <strong>You are responsible for your Multi Currency Account and/or matressbank Account after
                    closure.</strong> You agree that you will continue to be responsible for all obligations related to
                    your Multi Currency Account and/or matressbank Account even after it is closed.</p>

                <h2>20. Intellectual property rights</h2>
                <p>20.1 <strong>While you are using our Services, you may use the matressbank Materials only for your
                    personal use and solely as necessary in relation to those Services.</strong></p>
                <p>20.2 "matressbank Materials" include any software (including without limitation the App, the API,
                    developer tools, sample source code, and code libraries), data, materials, content and printed and
                    electronic documentation (including any specifications and integration guides) developed and
                    provided by us or our affiliates to you, or available for download from our Website. You may not,
                    and may not attempt to, directly or indirectly:</p>
                <ul>
                    <li>transfer, sublicense, loan, sell, assign, lease, rent, distribute or grant rights in the
                        Services or the matressbank Materials to any person or entity;
                    </li>
                    <li>remove, obscure, or alter any notice of any of our trade marks, or other "intellectual property"
                        appearing on or contained within the Services or on any matressbank Materials;
                    </li>
                    <li>modify, copy, tamper with or otherwise create derivative works of any software included in the
                        matressbank Materials; or
                    </li>
                    <li>reverse engineer, disassemble, or decompile the matressbank Materials or the Services or apply
                        any other process or procedure to derive the source code of any software included in the
                        matressbank Materials or as part of the Services.
                    </li>
                </ul>
                <h2>21. Our Responsibility for Loss or Damage</h2>
                <p>21.1 <strong>We are responsible to you for foreseeable loss and damage caused by us.</strong> If we
                    do not reasonably meet our commitments to you, we are responsible for loss or damage you suffer that
                    is a foreseeable result of our breaking this contract or our failing to use reasonable care and
                    skill. We are not responsible for any loss or damage that is not foreseeable. Loss or damage is
                    foreseeable if either it is obvious that it will happen or if, at the time the contract was made,
                    both we and you knew it might happen, for example, if you discussed it with us during your account
                    sign up process.</p>
                <p>21.2 <strong>We do not exclude or limit in any way our liability to you where it would be unlawful to
                    do so.</strong> This includes liability for death or personal injury caused by our negligence or the
                    negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation.
                </p>
                <p>21.3 <strong>We are not liable for business losses.</strong> If you use our Services for any
                    commercial or business purpose we will have no liability to you for any loss of profit, loss of
                    business, business interruption, or loss of business opportunity.</p>
                <p>21.4 <strong>We are not liable for technological attacks.</strong> We will not be liable for any loss
                    or damage caused by a virus, or other technological attacks or harmful material that may infect your
                    computer equipment, computer programmes, data or other proprietary material related to your use of
                    our Services.</p>
                <p>21.5 <strong>We have no control over websites linked to and from our Website.</strong> We assume no
                    responsibility for their content or any loss or damage that may arise from your use of them.</p>
                <p>21.6 <strong>Our liability to you for unauthorised payments or our mistake.</strong> In case of an
                    unauthorized payment or mistake due to our error, we shall at your request immediately refund the
                    payment amount including all fees deducted by us. This shall not apply:</p>
                <ul>
                    <li>(a) where your matressbank Account, or its personalised security features, are lost, stolen or
                        misappropriated. You will be liable for the first 50 EUR of any unauthorised payments if we
                        believe you should have been aware of the loss, theft or unauthorised use. We will not hold you
                        liable for the first 50 EUR if the unauthorised payment was caused either by our acts or
                        omissions, or those of a third party expressly carrying out activities on our behalf. Your
                        liability for the first 50 EUR also does not apply to any unauthorised transactions made after
                        you have notified us that your matressbank Account may have been compromised;
                    </li>
                    <li>(b) if you have acted fraudulently, in which case we will not refund you in any circumstances;
                    </li>
                    <li>(c) if you do not quickly notify us of security issues on your matressbank Account (e.g., loss
                        of your password), you remain liable for losses incurred up to your notification to us;
                    </li>
                    <li>(d) if the payment transaction was unauthorised but you have with intent or gross negligence
                        compromised the security of your matressbank Account or failed to comply with your obligations
                        to use your matressbank Account in the manner set out in this terms and conditions. In such a
                        case you shall be solely liable for all losses;
                    </li>
                    <li>(e) if you don’t let us know about the unauthorized or incorrectly completed transaction within
                        thirteen (13) months from the date of the payment transaction.
                    </li>
                </ul>
                <p>21.7 <strong>You are responsible for checking your matressbank Account regularly.</strong> We rely on
                    you to regularly check the transactions history of your matressbank Account and to contact Customer
                    Support immediately in case you have any questions or concerns.</p>
                <p>21.8 <strong>We are not liable for things which are outside of our control.</strong> We (and our
                    affiliates) cannot be liable for our inability to deliver or delay as a result of things which are
                    outside our control.</p>
                <p>21.9 <strong>You are liable for breaking these terms and conditions or applicable laws.</strong> In
                    the unlikely event of loss or claims or costs and expenses arising out of your breach of these terms
                    and conditions, any applicable law or regulation and/or your use of our Services, you agree to
                    compensate us and our affiliates and hold us harmless. This provision will continue after our
                    relationship ends.</p>
                <p>21.10 <strong>What happens if you owe us money.</strong> In the event you are liable for any amounts
                    owed to us, we may immediately remove such amounts from your Multi Currency Account (if available).
                    If there are insufficient funds in your Multi Currency Account to cover your liability, we reserve
                    the right to collect your debt to us by using any payments received in your Multi Currency Account
                    and other means you agree to reimburse us through other means. We may also recover amounts you owe
                    us through legal means, including, without limitation, through the use of a debt collection agency.
                </p>

                <h2>22. Accessing Our Services</h2>
                <p>22.1 <strong>We will try to make sure our Services are available to you when you need
                    them.</strong> However, we do not guarantee that our Services will always be available or be
                    uninterrupted. We may suspend, withdraw, discontinue or change all or any part of our Services
                    without notice. We will not be liable to you if for any reason our Services are unavailable at any
                    time. You are responsible for making all arrangements necessary for you to have access to our
                    Services. If you have granted permission to a third party to access your account, we may refuse
                    access to that third party if we are concerned about unauthorized or fraudulent access by that third
                    party. We will give you notice if we do this, either before or immediately after we refuse access,
                    unless notifying you would be unlawful or compromise our reasonable security measures.</p>

                <h2>23. Information Security</h2>
                <p>23.1 <strong>You are responsible for configuring your information technology, computer programmes and
                    platform in order to access our Services.</strong> You should use your own virus protection
                    software. We take security very seriously at matressbank and we work hard, using state-of-the-art
                    security measures, to make sure that your information remains secure. Our Services are a safe and
                    convenient way to send money to friends and family and to other people that you trust. However, we
                    do advise you to consider very carefully before sending money to anyone that you do not know well.
                </p>
                <p>23.2 <strong>You must not misuse our Services.</strong> You must not misuse our Services by
                    introducing viruses, trojans, worms, logic bombs or other materials which are malicious or
                    technologically harmful. You must not attempt to gain unauthorized access to our Website, our
                    servers, computers or databases. You must not attack our Website with any type of denial of service
                    attack. By breaching this provision, you would commit a criminal offense under Book II Criminal
                    Code. We will report any such breach to the relevant law enforcement authorities and we will
                    cooperate with those authorities by disclosing your identity to them. In the event of such a breach,
                    your right to use our Website and/or our Services will cease immediately. If you are aware of anyone
                    or any entity that is using the Services inappropriately, please contact us. Similarly, if you
                    receive any emails, purporting to be from matressbank, which you suspect may be phishing emails,
                    please forward the email to our Customer Service.</p>
                <p>23.3 <strong>Monitoring your matressbank Account login.</strong> In addition to your credentials, we
                    gather data from your devices and the way you login to try to detect any fraudulent or suspicious
                    attempt to access your matressbank Account. We do not guarantee that, even by collecting this data,
                    we’ll be able to prevent a fraudulent transaction or suspicious attempt to access your matressbank
                    Account. If we manage to detect a fraudulent or suspicious attempt of access, we may block it as
                    deemed necessary.</p>

                <h2>24. Linking to Our Site</h2>
                <p>24.1 <strong>You may link to our Website provided you follow certain rules.</strong> You may link to
                    our Website, provided:</p>
                <ul>
                    <li>(a) you do so in a way that is fair and legal and does not damage our reputation or take
                        advantage of it;
                    </li>
                    <li>(b) you do not suggest any form of association, approval or endorsement on our part where none
                        exists;
                    </li>
                    <li>(c) you do not frame our Website on any other site;</li>
                </ul>
                <p>We reserve the right to withdraw linking permission without notice.</p>
                <h2>25. When We Can End This Terms and Conditions or Suspend Our Services</h2>
                <p>25.1 <strong>We may end this terms and conditions by giving you one (1) month notice.</strong> We may
                    end these terms and conditions and close your Multi Currency Account and/or matressbank Account or
                    any service associated with it by giving you two (2) months’ prior notice.</p>
                <p>25.2 <strong>We may suspend or close your Multi Currency Account and/or matressbank Account without
                    notice in certain circumstances.</strong> We may at any time suspend or close your Multi Currency
                    Account and/or matressbank Account and/or end this terms and conditions without notice if:</p>
                <ul>
                    <li>(a) you breach any provision of this terms and conditions or documents referred to in this terms
                        and conditions;
                    </li>
                    <li>(b) we are requested or directed to do so by any competent court of law, government authority,
                        public agency, or law enforcement agency;
                    </li>
                    <li>(c) we have serious reasons to believe you are in breach of any applicable law or regulation;
                        or
                    </li>
                    <li>(d) we have serious reasons to believe you are involved in any fraudulent activity, money
                        laundering, terrorism financing or other criminal or illegal activity.
                    </li>
                </ul>
                <p>25.3 <strong>We may suspend your Multi Currency Account and/or matressbank Account for security
                    reasons.</strong> We may suspend your Multi Currency Account and/or matressbank Account or restrict
                    its functionality if we have reasonable concerns about:</p>
                <ul>
                    <li>(a) the security of your Multi Currency Account and/or matressbank Account; or</li>
                    <li>(b) suspected unauthorized or fraudulent use of your Multi Currency Account and/or matressbank
                        Account.
                    </li>
                </ul>
                <p>25.4 <strong>We will give you notice of suspension where possible.</strong> We will give you notice
                    of any suspension or restriction and the reasons for such suspension or restriction as soon as we
                    can, either before the suspension or restriction is put in place, or immediately after, unless
                    notifying you would be unlawful or compromise our reasonable security measures. We will lift the
                    suspension and/or the restriction as soon as practicable after the reasons for the suspension and/or
                    restriction have ceased to exist.</p>
                <p>25.5 <strong>You cannot use the App if this terms and conditions ends.</strong> On termination for
                    any reason all rights granted to you in connection with the App shall cease, you must immediately
                    delete or remove the App from your devices.</p>

                <h2>26. Our Right to Make Changes</h2>
                <p>26.1 <strong>We may change these terms and conditions by giving you at least two (2) months’ prior
                    written notice.</strong> If we do this, you can terminate these terms and conditions immediately and
                    free of charge at any time before the proposed date of the entry into force of the proposed changes,
                    by providing written notice to us. If we do not hear from you during the notice period, you will be
                    considered as having accepted the proposed changes and they will apply to you from the effective
                    date specified on the notice.</p>
                <p>26.2 <strong>In some instances, we may change these terms and conditions
                    immediately.</strong> Despite section 26.1, changes to this terms and conditions which are (1) more
                    favorable to you; (2) required by law; or (3) related to the addition of a new service, extra
                    functionality to the existing Services; or (4) changes which neither reduce your rights nor increase
                    your responsibilities, will come into effect immediately if they are stated in the change notice.
                    Changes to exchange rates shall come into effect immediately without notice and you shall not have
                    the right to object to such a change.</p>

                <h2>27. How We May Contact You</h2>
                <p>27.1 <strong>We usually contact you via email.</strong> For this purpose, you must at all times
                    maintain at least one valid email address in your matressbank Account profile. You should check for
                    incoming messages regularly and frequently; these emails may contain links to further communication
                    on our Website. If you don’t maintain or check your email and other methods of communications, you
                    will miss emails about your transactions and our Services. We cannot be liable for any consequence
                    or loss if you don’t do this. If we have reasonable concerns either about the security of your Multi
                    Currency Account and/or matressbank Account, or any suspected or actual fraudulent use of your Multi
                    Currency Account and/or matressbank Account, we will contact you via telephone, email, or both
                    (unless contacting you would be unlawful or compromise our reasonable security measures).</p>
                <p>27.2 <strong>Other ways we may contact you.</strong> In addition to communicating via email, we may
                    contact you via letter or telephone where appropriate. If you use any mobile services, we may also
                    communicate with you via SMS. Any communications or notices sent by:</p>
                <ul>
                    <li>Email will be deemed received by you on the same day if it is received in your email inbox
                        before 5pm on a Business Day. If it is received in your email inbox after 5pm on a Business Day
                        or at any other time, it will be deemed received on the next Business Day.
                    </li>
                    <li>Post will be deemed received 5 days from the date of posting.</li>
                    <li>SMS will be deemed received the same day.</li>
                </ul>
                <p>27.3 <strong>Where legislation requires us to provide information to you on a durable
                    medium,</strong> we will either send you an email (with or without attachment) or send you a
                    notification pointing you to information on our Website in a way that enables you to retain the
                    information in print format or other format that can be retained by you permanently for future
                    reference. Do keep copies of all communications we send or make available to you.</p>
                <p>27.4 <strong>If you need a copy of the current terms and conditions or any other relevant
                    document,</strong> please contact Customer Support.</p>
                <p>27.5 <strong>This terms and conditions are made in the English language.</strong> Documents or
                    communications in any other languages are for your convenience and only the English language version
                    of them are official.</p>

                <h2>28. Complaints</h2>
                <p>28.1 <strong>If you have any complaints about us or our Services,</strong> you may contact us
                    following our customer complaint procedure.</p>

                <h2>29. Other Important Terms</h2>
                <p>29.1 <strong>Nobody else has any rights under these terms and conditions.</strong> These terms and
                    conditions are between you and us. No other person shall have any rights to enforce any of its
                    terms. Neither of us will need to get the terms and conditions of any other person in order to end
                    or make any changes to these terms and conditions.</p>
                <p>29.2 <strong>We may transfer this terms and conditions to someone else.</strong> You may not
                    transfer, assign, mortgage, charge, subcontract, declare a trust over or deal in any other manner
                    with any or all of your rights and obligations under this terms and conditions (including the
                    matressbank Account) without our prior written consent. We reserve the right to transfer, assign or
                    novate this terms and conditions (including the matress bank Account) or any right or obligation
                    under these terms and conditions at any time without your consent and if permissible by law. This
                    does not affect your rights to close your Multi Currency Account and/or matressbank Account under
                    section 19.</p>
                <p>29.3 <strong>If a court finds part of these terms and conditions illegal, the rest will continue in
                    force.</strong> Each of the paragraphs of this terms and conditions operates separately. If any
                    court or relevant authority decides that any of them are unlawful, the remaining paragraphs will
                    remain in full force and effect.</p>
                <p>29.4 <strong>Even if we delay in enforcing these terms and conditions, we can still enforce it
                    later.</strong> If we delay in asking you to do certain things or in taking action, it will not
                    prevent us from taking steps against you at a later date.</p>
                <p>29.5 <strong>This terms and conditions supersedes any other previous terms and
                    conditions.</strong> This terms and conditions supersedes and extinguishes all previous terms and
                    conditions between you and matress bank, whether written or oral, relating to its subject matter.
                </p>
            </div>
            <Footer/>
        </>
    )
        ;
};

export default TermsConditions;
