// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.custom-card {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.settingsformInput {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.settingsButtonInput {
  width: 40%;
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: #27ca9f;
  color: #fff;
}

.error {
  color: red;
  text-align: center;
}

@media (max-width: 768px) {  
  .settingsButtonInput {
      width: 100%; 
  }

  .settingsformInput{
      width: 100%;
  }

  .custom-card{
     width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/settings.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,cAAc;EACd,aAAa;EACb,wCAAwC;EACxC,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE;MACI,WAAW;EACf;;EAEA;MACI,WAAW;EACf;;EAEA;KACG,UAAU;EACb;AACF","sourcesContent":[".settings-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100vh;\r\n}\r\n\r\n.custom-card {\r\n  max-width: 600px;\r\n  width: 100%;\r\n  margin: 0 auto;\r\n  padding: 20px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n  border-radius: 8px;\r\n  background-color: #fff;\r\n}\r\n\r\n.settingsformInput {\r\n  width: 100%;\r\n  margin-top: 2%;\r\n  margin-bottom: 2%;\r\n}\r\n\r\n.settingsButtonInput {\r\n  width: 40%;\r\n  margin-top: 2%;\r\n  margin-bottom: 2%;\r\n  background-color: #27ca9f;\r\n  color: #fff;\r\n}\r\n\r\n.error {\r\n  color: red;\r\n  text-align: center;\r\n}\r\n\r\n@media (max-width: 768px) {  \r\n  .settingsButtonInput {\r\n      width: 100%; \r\n  }\r\n\r\n  .settingsformInput{\r\n      width: 100%;\r\n  }\r\n\r\n  .custom-card{\r\n     width: 80%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
