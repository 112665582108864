import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import '../styles/recent-transactions.css';

const RecentTransactions = ({ transactions }) => {
    const itemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);

    if (!transactions) {
        return (
            <div className="recent-transactions">
                <Spinner color="primary" />
            </div>
        );
    }

    if (transactions.length === 0) {
        return (
            <div className="recent-transactions">
                <h3 className="noTransactions">No transactions available.</h3>
            </div>
        );
    }

    const totalPages = Math.ceil(transactions.length / itemsPerPage);

    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const currentTransactions = transactions.slice(startIdx, endIdx);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="recent-transactions">
            <h3>Recent Transactions</h3>
            <table>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {currentTransactions.map((transaction, index) => (
                        <tr key={index}>
                            <td>{transaction.type}</td>
                            <td style={{ color: transaction.type === 'Expense' ? 'red' : 'green' }}>
                                {transaction.type === 'Expense' ? '-' : ''}${Math.abs(transaction.amount).toFixed(2)}
                            </td>
                            <td>{transaction.date}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination */}
            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default RecentTransactions;
