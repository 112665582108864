import React, {useEffect, useState} from 'react';
import '../styles/landing.css';
import Navbar from "../components/navbar.tsx";
import Footer from "../components/footer.tsx";
import {Helmet} from 'react-helmet-async';
import MobileNavbar from "../components/mobileNavBar.tsx";
import {Col, Row} from 'reactstrap';
import {FaCreditCard, FaFolderOpen, FaGlobe, FaHandHoldingUsd, FaMoneyBillWave, FaUsers} from 'react-icons/fa';
import {TypeAnimation} from 'react-type-animation';
import image1 from '../images/img-1.png';
import image2 from '../images/img-2.png';
import image3 from '../images/img-3.png';
import image4 from '../images/img-4.png';
import image5 from "../images/img-5.png";
import image6 from "../images/img-6.png"
import image7 from "../images/img-7.png"



const LandingPage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const scrollToSection = (sectionId) => {
        const targetSection = document.getElementById(sectionId);
        targetSection.scrollIntoView({ behavior: 'smooth' });

    };



    return (
        <>
            <Helmet>
                <title>Mattress Bank - Banking Beyond Borders</title>
                <meta name='description' content=''/>
            </Helmet>
            {isMobile ? <MobileNavbar/> : <Navbar/>}

            <div className="main-container">
                <div className="container-one" id="open-account">
                    <div className="main-content">
                        <div className="text-content">
                            <TypeAnimation
                                sequence={[
                                    'The Money-App For The Diaspora.',
                                    1500,
                                    'The Money-App For The Expats.',
                                    1500,
                                    'The Money-App For The Travelers.',
                                    1500,
                                ]}
                                wrapper="span"
                                speed={20}
                                style={{fontSize: '2rem', display: 'inline-block', marginBottom: '1rem', fontWeight: 'bold', color: '#27ca9f'}}
                                repeat={Infinity}
                            />

                            <p className="paragraph">
                                Transform your financial experience with MattressBank – your all-in-one solution for making, saving, spending, borrowing, and moving money effortlessly. Dive into a world where you can transact in 30 different currencies, across 50 countries, all within a single, user-friendly platform. Say goodbye to the shackles of hidden fees and liberate yourself from the complexities of unpredictable exchange rate markups. Experience seamless and transparent financial transactions with MattressBank.
                            </p>

                            <br/>
                            <div className="buttons">
                                <a href="signup" className="button">
                                    Open an account
                                </a>
                                <a href="signin" className="button-right">
                                    Send money now
                                </a>
                            </div>

                        </div>

                        <div className="image-content-one">
                            <img
                                src={image1}
                                alt="MattressBank TSX Image"
                                className="tsx-image"
                            />
                        </div>
                    </div>

                </div>

                <div className="container-two" id="cross-border-transfers">
                    <div className="main-content">
                        <div className="image-content">
                            <img
                                src={image2}
                                alt="MattressBank TSX Image"
                                className="tsx-image"
                            />
                        </div>
                        <div className="text-content">
                            <h1>Move Your Money Instantly</h1>
                            <p className="paragraph">
                                Experience financial liberation through our state-of-the-art cross-border money transfer
                                services. Connect with 40 countries, enabling transactions in 30 diverse currencies.
                                Free yourself from hidden fees and escape the complexities of unpredictable exchange
                                rate markups. Enjoy the convenience of seamless international transactions, where
                                transparency, efficiency, and your financial well-being are at the forefront.
                            </p>


                            <div className="buttons">
                                <a href="signin" className="button">
                                    Send money now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-three" id="bank-the-way-you-want-section">
                    <div className="main-content">
                        <div className="text-content">
                            <h1>Bank The Way You Want</h1>
                            <p className="paragraph">
                                Welcome to the era of secure digital banking with MattressBank! Bid farewell to the outdated practice of stashing your hard-earned savings under the bed mattress. With MattressBank, you can now safeguard and manage your finances with confidence in the digital realm. No more worrying about the safety of your funds. Open your MattressBank account in just a few minutes and embrace the convenience of modern, secure banking.
                            </p>

                            <br/>
                            <div className="buttons">
                                <a href="signup" className="button">
                                    Open an account
                                </a>
                            </div>
                        </div>
                        <div className="image-content-two">
                            <img
                                src={image3}
                                alt="MattressBank TSX Image"
                                className="tsx-image"
                            />
                        </div>
                    </div>
                </div>

                <div className="container-four" id="multi-currency-wallets">
                    <div className="main-content">
                        <div className="image-content">
                            <img
                                src={image7}
                                alt="MattressBank TSX Image"
                                className="tsx-image"
                            />
                        </div>
                        <div className="text-content">
                            <h1>Money Without Borders</h1>
                            <p className="paragraph">
                                Open e-wallet accounts in multiple currencies to send or spend abroad. Store value,
                                request,
                                receive or send money in the currency of your choice.
                                When your gut-feeling or research tells you that the Euro is going to rise in value
                                against
                                the dollar, you can literally move money from your USD wallet to the Euro wallet in
                                seconds.


                            </p>
                            <br/>
                            <div className="buttons">
                                <a href="signup" className="button">
                                    Open an account
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-five" id="virtual-physical-cards">
                    <div className="main-content">
                        <div className="text-content">
                            <TypeAnimation
                                sequence={[
                                    'Mattress Virtual Card.',
                                    1500,
                                    'Mattress Physical Card.',
                                    1500,
                                ]}
                                wrapper="span"
                                speed={20}
                                style={{fontSize: '2.5rem', display: 'inline-block', marginBottom: '1rem', fontWeight: 'bold', color: '#27ca9f'}}
                                repeat={Infinity}
                            />
                            <p className="paragraph">
                                Order your Mattress multi-currency debit card that you can swipe anywhere, worldwide or
                                can
                                use for your online purchases.
                                You can access your money at all times with the Mattress multi-currency card. Withdraw
                                money
                                at any ATM at the currency exchange market rate, no hidden fees.

                            </p>
                            <br/>
                            <div className="buttons">
                                <a href="signup" className="button">
                                    Open a Mattress Card
                                </a>
                            </div>
                        </div>
                        <div className="image-content-three">
                            <img
                                src={image4}
                                alt="MattressBank TSX Image"
                                className="tsx-image"
                            />
                        </div>

                    </div>
                </div>

                <div className="container-six" id="soft-loans">
                    <div className="main-content">
                        <div className="image-content">
                            <img
                                src={image6}
                                alt="MattressBank TSX Image"
                                className="tsx-image"
                            />
                        </div>
                        <div className="text-content">
                            <h1>Soft Loans</h1>
                            <p className="paragraph">
                                Get soft loans on rainy days & emergencies. You can easily get a soft loan for
                                emergencies
                                for your loved ones back home or when abroad.  As a regular user of MattressBank,
                                soft
                                loan
                                interest rates can start from 0%, no hidden fees, confusing terms & waiting around.
                            </p>
                            <br/>
                            <div className="buttons">
                                <a href="signin" className="button">
                                    Request a soft loan
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-seven" id="joint-accounts-money-pots">
                <div className="main-content">
                        <div className="text-content">
                            <TypeAnimation
                                sequence={[
                                    'Joint Accounts.',
                                    1500,
                                    'Joint Money Pots.',
                                    1500,
                                ]}
                                wrapper="span"
                                speed={20}
                                style={{fontSize: '2.5rem', display: 'inline-block', marginBottom: '1rem', fontWeight: 'bold', color: '#27ca9f'}}
                                repeat={Infinity}
                            />
                            <p className="paragraph">
                                You want to contribute or save towards a certain goal or planned social event. You can
                                easily open joint accounts as communities, create multi-currency wallets and money pots
                                where you can easily save money for set goals.
                            </p>
                            <br/>
                            <div className="buttons">
                                <a href="signup" className="button">
                                    Open joint accounts
                                </a>
                            </div>
                        </div>
                        <div className="image-content-three">
                            <img
                                src={image5}
                                alt="MattressBank TSX Image"
                                className="tsx-image"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-eight" >
                <div className="product-heading">
                    <h1 className="headingProducts">Our Products</h1>
                </div>
                <div className="main-content">
                    <Row className="product-features">
                        <Col xs="12" md="6">
                            <div className="product-feature" onClick={() => scrollToSection('open-account')}>
                                <div className="icon"><FaFolderOpen size={40}/></div>
                                <h3>Open an Account</h3>
                                <p>Experience seamless account opening in minutes. Enjoy banking with a smile!</p>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="product-feature" onClick={() => scrollToSection('cross-border-transfers')}>
                                <div className="icon"><FaGlobe size={40}/></div>
                                <h3>Cross-border Money Transfers</h3>
                                <p>Easily transfer money across borders and around the world.</p>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="product-feature" onClick={() => scrollToSection('multi-currency-wallets')}>
                                <div className="icon"><FaMoneyBillWave size={40}/></div>
                                <h3>Multi-currency Wallets</h3>
                                <p>Store and manage money in multiple currencies with ease.</p>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="product-feature" onClick={() => scrollToSection('virtual-physical-cards')}>
                                <div className="icon"><FaCreditCard size={40}/></div>
                                <h3>Mattress Virtual & Physical Cards</h3>
                                <p>Order multi-currency debit cards for both virtual and physical use.</p>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="product-feature" onClick={() => scrollToSection('soft-loans')}>
                                <div className="icon"><FaHandHoldingUsd size={40}/></div>
                                <h3>Soft Loans</h3>
                                <p>Get soft loans for emergencies and rainy days. Financial support when you need
                                    it.</p>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="product-feature" onClick={() => scrollToSection('joint-accounts-money-pots')}>
                                <div className="icon"><FaUsers size={40}/></div>
                                <h3>Joint Accounts & Money Pots</h3>
                                <p>Collaborate and save towards common goals with joint accounts and money pots.</p>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
            <Footer/>
        </>
    );
};

export default LandingPage;
