import React, {useEffect, useState} from 'react';
import '../styles/terms.css';
import {Helmet} from "react-helmet-async";
import MobileNavbar from "../components/mobileNavBar.tsx";
import Navbar from "../components/navbar.tsx";
import Footer from "../components/footer.tsx";

const PrivacyPolicy: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    return (
        <>
            <Helmet>
                <title>Privacy Policy - MattressBank</title>
                <meta name='description' content='View our privacy policy for using MattressBank services.'/>
            </Helmet>
            {isMobile ? <MobileNavbar/> : <Navbar/>}
            <div className="terms-container">
                <h1>Privacy Policy</h1>
                <p>This Privacy Policy (“Policy”) explains how we collect, use, and process your personal data when you
                    use our website <a href="https://matressbank.com">https://matressbank.com</a> (“Website”), our web
                    application (“Web App”), our mobile app (“App”) and email messages that we send to you (jointly
                    called “Services”). If anything here applies to only one of our Services or to customers in a
                    particular country, we’ll explicitly point this out to you.</p>
                <p>By continuing your interactions with us, such as by submitting information to us, or using our
                    Services, you confirm that you understand and consent to the collection, use, disclosure, and
                    processing of your personal data (or the personal data of any individual you provide) as described
                    in this Privacy Policy.</p>
                <p>To help you understand the Privacy Policy, we have introduced a summary below. In case of a conflict,
                    please refer to the text in the section below.</p>

                <h2>1. Data We Collect About You</h2>
                <p><strong>Personal data, or personal information,</strong> means any information about an identified or
                    identifiable individual. It does not include anonymous data, which cannot be linked back to the
                    individual. We will collect and process personal data about you as follows:</p>

                <h2>2.1 Information You Give Us</h2>
                <ul>
                    <li>You may give us information about yourself when you sign up to use our Services, e.g., when you
                        provide us with personal details including your name and email address. This also includes
                        information you provide through your continued use of our Services, your participation in
                        discussion boards or other social media functions on our Website or App, through entering a
                        competition, promotion or survey, and by reporting problems with our Services.
                    </li>
                    <li>Additional information you give us for security, identification and verification purposes may
                        include your address, phone number, financial information (including credit card, debit card, or
                        bank account information), payment reason, geographical location, social security/insurance
                        number, national identification number, personal description, photograph, tax reference number,
                        proof of address, proof of residency, passport, and/or National ID.
                    </li>
                    <li>The content of your communications with us, which we collect via telephone call recordings,
                        online chat, emails, direct messaging and other means.
                    </li>
                    <li>In some cases, including when you send or receive high value or high volume transactions, or
                        where we need to comply with anti-money laundering regulations, we may also need more
                        identification information from you, including a copy of your bank account statements.
                    </li>
                </ul>
                <p>In providing the personal data of any individuals other than yourself, including connected persons,
                    you confirm that you have obtained consent from such individuals to disclose their personal data to
                    us or are otherwise entitled to provide this information to us. You also confirm that you have
                    brought this Policy to their attention if legally necessary, and have received their consent to our
                    collection, use, and disclosure of such personal data for the purposes set out in this Policy. The
                    term ‘connected person’ means an individual connected to matressbank through the use of our Services
                    and could be an account holder, payment beneficiary, recipient of a designated payment, guarantor,
                    director, shareholder, partners or members of a partnership, trustee, authorized signatory of a
                    designated account, a friend you have recommended, individuals in your contact list or any other
                    person who has a relevant relationship with matressbank.</p>

                <p>2.2 Information We Collect About You</p>
                <ul>
                    <li>Details of the transactions you carry out when using our Services, including the geographic
                        location from which the transaction originates.
                    </li>
                    <li>Technical information, including the internet protocol (IP) address used to connect your device
                        to the Internet, your login information, browser type and version, time zone setting, browser
                        plug-in types and versions, operating system and platform, and if you've installed the App,
                        installed applications on your mobile device that have remote access permissions.
                    </li>
                    <li>Information about your visit, including the full Uniform Resource Locators (URL) clickstream to,
                        through and from our Website or App (including date and time); products you viewed or searched
                        for; page response times, download errors, length of visits to certain pages, page interaction
                        information (including scrolling, clicks, and mouse-overs), and methods used to browse away from
                        the page and any phone number used to call our Customer Support service.
                    </li>
                    <li>Information about your marketing and communication preferences.</li>
                </ul>
                <p>2.3 Information We Receive from Other Sources</p>
                <p>We may receive information about you if you use any of the other websites we operate or the other
                    services we provide. We also work closely with third parties and may receive information about you
                    from them. This may include:</p>
                <ul>
                    <li>The payment service providers you use to transfer money to us will provide us with your personal
                        information, including your name and address, as well as your financial information, including
                        your bank account details.
                    </li>
                    <li>The bank whose account you link to your matressbank account for the purpose of satisfying
                        regulatory verification may provide us with your name, address, and financial information,
                        including source of funds and bank statement information.
                    </li>
                    <li>If you are a ‘connected person’ for a matressbank customer, then that matressbank customer may
                        provide your personal information to us:
                        <ul>
                            <li>Payment beneficiaries: name, account details, email, and additional verification
                                information if requested by the recipient bank.
                            </li>
                            <li>Directors and ultimate beneficial owners: name, date of birth, and country of
                                residence.
                            </li>
                        </ul>
                    </li>
                    <li>Business partners may provide us with your name and address, as well as financial information,
                        including card payment information.
                    </li>
                    <li>Advertising networks, analytics providers, and search information providers may provide us with
                        pseudonymized information about you, including confirmation of how you found our website.
                    </li>
                    <li>In some jurisdictions, we may check the information you have provided to us with governmental or
                        private identity record databases or with credit reference agencies to confirm your identity and
                        to combat fraud.
                    </li>
                </ul>

                <p>2.4 Information from Social Networks</p>
                <p>If you log into our Services using your social network account (including Apple ID, Facebook, or
                    Google) we will receive relevant information that is necessary for us to enable our Services to
                    authenticate your access. The social network will provide us with access to certain information that
                    you have provided to them, including your name, profile image, and email address, in accordance with
                    the social network service provider's privacy policy. We use such information, together with any
                    other information you directly provide to us when registering or using our Services, to create your
                    account and to communicate with you about the information, products, and services that you request
                    from us. You may also be able to specifically request that we have access to the contacts in your
                    social network account.</p>

                <p>2.5 Sensitive Data</p>
                <p>As part of our identity verification process we collect, use, and store biometric data, namely:</p>
                <ul>
                    <li>We extract face scan information from photos and videos to compare pictures of you on identity
                        documents with each other and with a selfie that you provide to verify your identity and for
                        anti-fraud checks, and to improve these processes. We may ask you to specifically consent to the
                        collection, use, and storage of your biometric data during the verification process, where
                        privacy regulations require it in your jurisdiction. If you do not consent, we offer alternate
                        methods to verify your identity which may take longer. The same documents and photos are
                        required for both processes.
                    </li>
                    <li>We will not disclose or disseminate any biometric data to anyone other than our identity
                        verification providers, or when required by applicable laws and regulations, or pursuant to a
                        valid order from a court. We never sell, lease, trade, or benefit from your biometric data.
                    </li>
                    <li>We will retain biometric data for the period necessary to complete the identity verification
                        process, and in any case, no longer than 1 year after collection, unless required by law or
                        legal process to keep it longer.
                    </li>
                    <li>We monitor the way you login and interact with our website or app to validate your identity and
                        support the detection of fraudulent and suspicious attempts to access your matressbank Account.
                    </li>
                    <li>If you consent to linking your bank account to your matressbank account for the purpose of
                        satisfying regulatory verification, we may also process a limited amount of sensitive data when
                        we carry out verification of your financial documents.
                    </li>
                </ul>
                <p>Your jurisdiction may have rules that classify other information described in section 2 as sensitive.
                    All sensitive information is subject to appropriate levels of protection.</p>
                <p>2.6 Children’s Data</p>
                <p>Our products and services are directed at adults, and are not intended for children. We therefore do
                    not knowingly collect data from children. Any data collected from a child before their age is
                    determined will be deleted.</p>

                <h2>3. How We Protect Your Personal Information</h2>
                <p>3.1 We take the safeguarding of your information very seriously. The transmission of information via
                    the internet is not completely secure. Although we do our best to protect your personal data, we
                    cannot guarantee the security of your data during transmission, and any transmission is at your own
                    risk. Once we have received your information, we use strict procedures and security features to try
                    to prevent unauthorized access, including:</p>
                <ul>
                    <li>Communications over the Internet between you and matressbank systems are encrypted using strong
                        asymmetric encryption.
                    </li>
                    <li>We update and patch our servers in a timely manner.</li>
                    <li>Our technical security team proactively monitors for abnormal and malicious activity in our
                        servers and services.
                    </li>
                    <li>When information you’ve given us is not in active use, it is encrypted at rest.</li>
                </ul>
                <p>3.2 We restrict access to your personal information to those employees of matressbank who have a
                    business reason for knowing such information and third-party service providers processing data on
                    our behalf. All matressbank employees who have access to your personal data are required to adhere
                    to this Policy and all third-party service providers are requested by matressbank to ensure
                    appropriate safeguards are in place.</p>
                <p>3.3 We continuously educate and train our employees about the importance of confidentiality and
                    privacy of customer personal information. We maintain physical, technical and organizational
                    safeguards that comply with applicable laws and regulations to protect your personal information
                    from unauthorized access.</p>

                <h2>4. Ways We Use Your Information</h2>
                <p>4.1 <strong>Lawful basis:</strong> We will only use your personal data when the law allows us to.
                    Depending on the country in which you are located, we rely on the following legal bases to process
                    your personal data:</p>
                <ul>
                    <li>Where you have given us consent to process your data.</li>
                    <li>Where it is necessary for our legitimate interests (or those of a third party) and your
                        interests and fundamental rights do not override those interests.
                    </li>
                    <li>Where we have a legal obligation to process your personal data.</li>
                    <li>Where it is necessary to fulfill our obligations under a contract with you.</li>
                    <li>Where it is necessary to protect the vital interests of yourself or other individuals.</li>
                </ul>
                <p>4.2 <strong>Purposes for which we will use your personal data:</strong> the ways we plan to use your
                    personal data are described below, including which of the legal bases we rely on to do so in the UK,
                    the EU. We have also identified what our legitimate interests are where appropriate.</p>
                <ul>
                    <li><strong>To carry out our obligations relating to your contract with us for provision of payments
                        services and multi-currency accounts</strong>
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Necessary to
                                fulfill our obligations under a contract
                            </li>
                        </ul>
                    </li>
                    <li><strong>To provide you with information, products and services</strong>
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legitimate interest
                                (to keep our records up-to-date, decide which of our products and services may be of
                                interest to you, to tell you about them and deliver them to you)
                            </li>
                            <li>Where you’ve consented for us to process your personal data in a certain way</li>
                        </ul>
                    </li>
                    <li><strong>To comply with any applicable legal and/or regulatory requirements</strong>, including
                        to respond to requests from public and government authorities, including public and government
                        authorities outside your country of residence, upon demonstration of lawful authority, and to
                        comply with court orders from the relevant jurisdiction.
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legal obligation,
                                Necessary to fulfill our obligations under a contract, Legitimate interest (to be
                                efficient about how we meet our legal obligations and to comply with regulations that
                                apply to us)
                            </li>
                        </ul>
                    </li>
                    <li><strong>To prevent and detect crimes</strong>, including fraud and financial crime
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legal obligation,
                                Legitimate interest (to detect and prevent criminal activity in connection with our
                                Services and improve how we manage instances of suspected financial crime)
                            </li>
                        </ul>
                    </li>
                    <li><strong>To notify you about changes to our Services and send you other administrative
                        information</strong>
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legal obligation,
                                Legitimate interest (to provide you with a good customer service and keep you up-to-date
                                with new developments), Necessary to fulfill our obligations under a contract
                            </li>
                        </ul>
                    </li>
                    <li><strong>As part of our efforts to keep our Services safe and secure</strong>
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legal obligation,
                                Legitimate interest (protecting our customers and ourselves from loss or harm),
                                Necessary to fulfill our obligations under a contract
                            </li>
                        </ul>
                    </li>
                    <li><strong>To administer our Services and for internal operational, planning, audit,
                        troubleshooting, data analysis, testing, research, statistical and survey purposes</strong>
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legitimate interest
                                (to keep our records up to date, efficiently fulfill our legal and contractual duties,
                                carry out our administrative operations, and develop new and existing products and
                                services)
                            </li>
                        </ul>
                    </li>
                    <li><strong>To undertake system or product development</strong>, including helping third party
                        suppliers improve the services they provide to us, to improve our Services and to ensure that
                        they are presented in the most effective manner
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legitimate interest
                                (to develop existing and new products and services and to efficiently meet our legal and
                                contractual obligations)
                            </li>
                        </ul>
                    </li>
                    <li><strong>To allow other matressbank customers to request or send money to you</strong> through
                        our services when providing information that matches your phone number or email address
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legitimate interest
                                (to provide an efficient and innovative service to our customers), Necessary to fulfill
                                our obligations under a contract
                            </li>
                        </ul>
                    </li>
                    <li><strong>To measure or understand the effectiveness of advertising we serve</strong> and to
                        deliver relevant advertising to you
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legitimate interest
                                (to market our products and services in the most efficient manner)
                            </li>
                            <li>Where you’ve consented for us to process your personal data in a certain way.</li>
                        </ul>
                    </li>
                    <li><strong>To allow you to participate in interactive features of our Services</strong>, when you
                        choose to do so
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legitimate interest
                                (to provide an efficient and innovative service to our customers)
                            </li>
                            <li>Where you’ve consented for us to process your personal data in a certain way.</li>
                        </ul>
                    </li>
                    <li><strong>To use your email address to provide you with information about other similar goods and
                        services we offer</strong>
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legitimate interest
                                (to market our products and services)
                            </li>
                            <li>Where you’ve consented for us to process your personal data in a certain way</li>
                        </ul>
                    </li>
                    <li><strong>To provide you, or permit selected third parties to provide you, with information about
                        goods or services we feel may interest you.</strong>
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Where you’ve
                                consented for us to process your personal data in a certain way.
                            </li>
                        </ul>
                    </li>
                    <li><strong>To take steps to recover amounts owed to us</strong>, including via insurance claims,
                        and to allow us to pursue available remedies or limit damages that we may sustain
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legitimate interest
                                (to protect our assets)
                            </li>
                        </ul>
                    </li>
                    <li><strong>To allow a third party or a financial institution that sent money to recover money
                        received by you in error or due to fraud</strong>
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Legal obligation,
                                Legitimate interest (to allow third parties to recover funds)
                            </li>
                        </ul>
                    </li>
                    <li><strong>On rare occasions, to help safeguard our customers, employees or other
                        individuals</strong> by notifying the emergency services
                        <ul>
                            <li>Lawful basis for processing, including basis of legitimate interest: Vital interest</li>
                        </ul>
                    </li>
                </ul>
                <h2>5. Disclosure of Your Personal Data</h2>
                <p>5.1 We may share your personal data with the following third parties:</p>
                <ul>
                    <li><strong>Affiliates, business partners, suppliers, and subcontractors</strong> for the
                        performance and execution of any contract we enter into with them or you and to help them
                        improve the services they provide to us;
                    </li>
                    <li><strong>Advertisers and advertising networks</strong> to select and serve relevant adverts to
                        you and others;
                    </li>
                    <li><strong>Analytics and search engine providers</strong> that assist us in the improvement and
                        optimization of our site;
                    </li>
                    <li><strong>In the event that we sell any of our business or assets or combine with another
                        organization</strong>, in which case we may disclose your personal data to the prospective buyer
                        of such business or assets or prospective organization with which our business or assets may be
                        combined;
                    </li>
                    <li><strong>Limited information is sent to payment beneficiaries</strong> when you initiate a
                        payment transaction;
                    </li>
                    <li><strong>If we are under a duty to disclose or share your personal data</strong> in order to
                        comply with any legal obligation, or in order to enforce or apply our Customer Agreement and
                        other applicable agreements, or to protect the rights, property, or safety of matressbank, our
                        customers, our employees, or others;
                    </li>
                    <li><strong>To prevent and detect fraud or crime</strong> and to assist us in conducting or
                        co-operating with investigations of fraud or other illegal activity where we believe it is
                        reasonable and appropriate to do so. Please note that if we, or a fraud prevention agency,
                        determine that a fraud or money laundering risk is posed, we may refuse to provide the services
                        requested or we may stop providing existing products and services to a customer. A record of any
                        fraud or money laundering risk will be retained by the fraud prevention agencies, and may result
                        in others refusing to provide services.
                    </li>
                    <li><strong>In response to a subpoena, warrant, court order, properly constituted police request or
                        as other matressbank required by law;</strong></li>
                    <li><strong>To assess financial and insurance risks;</strong></li>
                    <li><strong>To recover debt or in relation to your insolvency</strong> or to allow a party or a
                        financial institution that sent money to recover money received by you in error or due to fraud;
                    </li>
                    <li><strong>To develop customer relationships, services, and systems;</strong> and</li>
                    <li><strong>If you consent, to share your details when using our Services.</strong></li>
                </ul>
                <p>5.2 If you would like further information about who we have shared your data with, or to be provided
                    with a list specific to you, you can request this by writing to privacy@matressbank.com.</p>
                <h2>7. Sharing and Storing Your Personal Data</h2>
                <p>7.1 While our main processing centers are in the UK and the EU, we may transfer your data to and
                    store it in countries outside of your jurisdiction, which do not offer an equivalent level of
                    protection to your country. It may also be processed by staff operating outside of your
                    jurisdiction. Such staff may be engaged in activities that include the fulfillment of your payment
                    order, the processing of your payment details, and the provision of support services. We will take
                    all steps reasonably necessary to ensure that your data is treated securely and in accordance with
                    this Policy.</p>
                <p>7.2 In the above cases, we ensure that appropriate safeguards, including Standard Contractual Clauses
                    and/or International Data Transfer Agreements, are in place. A copy of these documents can be
                    provided by sending a request to privacy@matressbank.com.</p>
                <h2>9. Profiling and Automated Decision Making</h2>
                <p>9.1 We may use some elements of your data to customize our Services and the information we provide to
                    you, and to address your needs, including your country of residence and transaction history. For
                    example, if you frequently send funds from one particular currency to another, we may use this
                    information to inform you of new product updates or features that may be useful for you. When we do
                    this, we take all necessary measures to ensure that your privacy and security are protected - and we
                    use pseudonymised data wherever possible. This activity has no legal effect on you.</p>
                <p>9.2 We use automated processes to check that your application to access matressbank services and your
                    use of matressbank services meet our required standard, including verifying your identity, and to
                    help prevent fraud or other illegal activities. These processes may make an automated decision to
                    reject your application or a proposed transaction, to block a suspicious attempt to login to your
                    matressbank account, or to close your account. If this happens, you will be notified and offered the
                    opportunity to provide further information and challenge the decision through an appeal mechanism,
                    which includes a manual review. In any case, if you feel that an automated process may have impacted
                    you, please contact matressbank Customer Support.</p>

                <h2>10. Cookies</h2>
                <p>We use small files known as cookies to distinguish you from other users and see how you use our site
                    and products while providing you with the best experience. They also enable us to improve our
                    services. For more information on cookies, read our terms and conditions.</p>
                <h2>11. Data Retention</h2>
                <p>11.1 We will retain your personal data only for as long as is necessary to fulfill the purposes for
                    which we collected it. As a regulated financial institution, matressbank is required by law to store
                    some of your personal and transactional data beyond the closure of your account with us. We only
                    access your data internally on a need-to-know basis, and we’ll only access or process it if
                    absolutely necessary.</p>
                <p>11.2 We will always delete data that is no longer required by a relevant law or jurisdiction in which
                    we operate. We do this automatically, so you don’t need to contact us to ask us to delete your data.
                    Deletion methods include shredding, destruction and secure disposal of hardware and hard-copy
                    records, and deletion or over-writing of digital data.</p>
                <h2>13. Your Rights</h2>
                <p>13.1 Laws applicable in your jurisdiction may grant you certain rights regarding the information we
                    hold about you. If you have any questions in relation to our use of your personal information,
                    contact us at privacy@matressbank.com.</p>
                <p>13.2 Your exercise of these rights may be subject to certain exemptions, including to safeguard the
                    public interest (including the prevention or detection of crime), where it is in our interests
                    (including the maintenance of legal privilege), and where the rights of other persons are involved
                    (including where your request relates to information about other individuals).</p>
                <p>13.3 We may need to retain certain information for record-keeping purposes and to comply with our
                    obligations under applicable laws and regulations, including but not limited to our anti-money
                    laundering obligations, and/or to complete any transactions that you began prior to requesting a
                    change or deletion.</p>
                <p>13.4 You will not normally have to pay a fee to access your personal data or to exercise any of the
                    other rights. However, we may charge a reasonable fee where permitted by local laws or if your
                    request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with
                    your request in these circumstances.</p>
                <p>13.5 We may need to request specific information from you to help us confirm your identity and your
                    right to access your personal data or to exercise any of your other rights. This is a security
                    measure to ensure that personal data is not disclosed to any person who has no right to receive it.
                    We may also contact you to ask you for further information in relation to your request to speed up
                    our response.</p>
                <p>13.6 If you no longer want to receive marketing-related emails from us, you may opt-out by following
                    the instructions in any such email. We will try to comply with your request as soon as reasonably
                    practicable. Please note that if you opt-out, we may still send you important administrative
                    messages, from which you cannot opt-out.</p>
                <p>13.7 If you would like to make a request, the best way to do so would be to contact us at the contact
                    details listed in the Appendix.</p>
                <p>13.8 Subject to some country-specific variations, you have the right to:</p>

                <ul>
                    <li>Request a copy of the personal data we hold about you and to check that we are lawfully
                        processing it.
                    </li>
                    <li>Request correction of the personal data that we hold about you. We may need to verify the
                        accuracy of the new data you provide to us.
                    </li>
                    <li>Ask us to delete personal data where there is no good reason for us to continue to process it.
                        You may also have the right to ask us to delete your personal data where (i) you have
                        successfully exercised your right to object to processing (see below), (ii) where we may have
                        processed your personal data unlawfully or (iii) where we are required to delete your personal
                        data to comply with local law. We may not always be able to comply with your deletion request
                        for specific legal reasons which will be notified to you, if applicable, in our response to your
                        request, including financial regulations that may require us to hold your personal data for a
                        period after the closure of your account.
                    </li>
                    <li>Withdraw your consent for us to process data, where our lawful basis for processing is based on
                        that consent. Note that withdrawal of consent does not affect the lawfulness of processing which
                        may have taken place prior to withdrawal of consent. If you withdraw your consent, we may not be
                        able to provide certain products or services to you.
                    </li>
                    <li>Request us to cease direct marketing to you, or profiling you for the purpose of direct
                        marketing, by contacting us or adjusting your notification preferences in the settings section
                        of your account.
                    </li>
                    <li>Where we use wholly automated decision making-processes, request that we provide information
                        about the decision-making methodology and ask us to verify that an automated decision that
                        results in a legal impact on you has been made correctly. We may reject the request, as
                        permitted by applicable law, including when providing the information would result in a
                        disclosure of a trade secret or would interfere with the prevention or detection of fraud or
                        other crime. However, generally in these circumstances we will verify that the algorithm and
                        source data are functioning as anticipated without error or bias or if required by law to adjust
                        the processing.
                    </li>
                    <li>Object to any processing based on the legitimate interests ground when there is something about
                        your particular situation where you feel processing on this ground impacts your fundamental
                        rights and freedoms.
                    </li>
                    <li>Ask us to suspend the processing of your personal data in the following situations: (i) if you
                        want us to determine the data's accuracy; (ii) where our processing of the data is unlawful but
                        you do not want us to delete it at this time; (iii) where you wish us to retain the data even if
                        we no longer require it because you need it to establish, exercise or defend legal claims; or
                        (iv) you have objected to us using your data but we need to confirm whether or not we have
                        over-riding legitimate grounds to continue using it.
                    </li>
                    <li>Request the transfer of your personal data to a third party or yourself. We will provide you or
                        your chosen third party with the personal data you provided to us in a structured, commonly
                        used, machine-readable format. This right applies only to information where we used the
                        information to perform a contract with you or where you initially consented for us to use it.
                    </li>
                </ul>
                <h2>15. Other Jurisdictions</h2>
                <p>You may also have certain rights regarding the information we hold about you under other data
                    protection and privacy laws. Please contact us at <strong><a href="mailto:privacy@matressbank.com">privacy@matressbank.com</a></strong> for more
                    information.</p>
                <h2>16. Third-party links</h2>
                <p>Our Services may, from time to time, contain links to the websites of our partner networks,
                    advertisers and affiliates. Please note that these websites have their own privacy policies and that
                    we do not accept any responsibility for them, so if you follow a link, check these policies before
                    you submit any personal data to these websites.</p>
                <h2>17. Changes to our Privacy Policy</h2>
                <p>To keep up with changing legislation, best practice and changes in how we process personal
                    information, we may revise this Policy at any time by posting a revised version on this website. To
                    stay up to date on any changes, check back periodically.</p>
                <h2>18. Contact</h2>
                <p>18.1 Please send any questions, comments or requests about this Policy to our privacy team at <strong><a href="mailto:privacy@matressbank.com">privacy@matressbank.com</a></strong>, where you can
                    also contact our Data Protection Officer. You can also write
                    to us or our Data Protection Officer at our registered office that applies to you, as listed in the
                    Appendix.</p>
                <p>18.2 If you feel that we have not addressed your questions or concerns adequately, or you believe
                    that your data protection or privacy rights have been infringed, you can complain to any supervisory
                    authority or other public body with responsibility for enforcing privacy laws. </p>
            </div>
            <Footer />
        </>
    )
}
export default PrivacyPolicy