// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transfer-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.transfer-success-card {
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px; 
}

.review-details {
  padding: 20px;
  text-align: center;
}

.review-details h5 {
  color: #27ca9f;
}

@media (max-width: 768px) {
  .transfer-success-card {
      width: 90%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/transferSuccess.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,wCAAwC;EACxC,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;MACI,UAAU;EACd;AACF","sourcesContent":[".transfer-success-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100vh;\r\n}\r\n\r\n.transfer-success-card {\r\n  width: 100%;\r\n  max-width: 600px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n  margin: 10px; \r\n}\r\n\r\n.review-details {\r\n  padding: 20px;\r\n  text-align: center;\r\n}\r\n\r\n.review-details h5 {\r\n  color: #27ca9f;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .transfer-success-card {\r\n      width: 90%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
