import React from 'react';
import { FaMoneyBill, FaMoneyBillAlt, FaDollarSign } from 'react-icons/fa';

import '../styles/financial-overview.css';


const formatCurrency = (value) => {
    return `$${parseFloat(value).toFixed(2)}`;
};

const FinancialOverview = ({ balance, income, expenses, accountType, accountNumber }) => {
    return (
        <div className="financial-overview">
            <div className="overview-card">
                <div className="card-icon">
                    <FaMoneyBillAlt />
                </div>
                <div className="card-details">
                    <h3>A/C Balance</h3>
                    <h3><strong>{accountNumber}</strong></h3>
                    <p>{formatCurrency(balance)}</p>
                </div>
            </div>

            <div className="overview-card">
                <div className="card-icon">
                    <FaDollarSign />
                </div>
                <div className="card-details">
                    <h3>Total Income</h3>
                    <p>{formatCurrency(income)}</p>
                </div>
            </div>

            <div className="overview-card">
                <div className="card-icon">
                    <FaMoneyBill />
                </div>
                <div className="card-details">
                    <h3>Total Expenses</h3>
                    <p>{formatCurrency(expenses)}</p>
                </div>
            </div>
        </div>
    );
};

export default FinancialOverview;
