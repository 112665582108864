import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar as RSNavbar, Nav, NavItem, NavbarBrand } from 'reactstrap';
import { FaSignInAlt, FaUserPlus } from 'react-icons/fa';
import '../styles/navbar.css';
import logo from '../images/logo.svg';

const Navbar = () => {
    return (
        <RSNavbar className="navbar fixed-top" color="light" light expand="md">
            <NavbarBrand href="/" className="mobile-navbar-brand">
                <img src={logo} alt="logo" />
            </NavbarBrand>
            <Nav className="mr-auto hidden-md-up" navbar>
                <NavItem>
                    <NavLink to="/about" className="nav-link">
                        About Us
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/privacy" className="nav-link">
                        Privacy Policy
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/terms" className="nav-link">
                        Terms and Conditions
                    </NavLink>
                </NavItem>
            </Nav>
            <div className="ml-auto hidden-sm-down">
                <NavLink to="/signin" className="buttonNav">
                    Log In <FaSignInAlt />
                </NavLink>
                <NavLink to="/signup" className="buttonNav ghost-button">
                    Sign Up <FaUserPlus />
                </NavLink>
            </div>
        </RSNavbar>
    );
};

export default Navbar;
