import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import logo from '../images/logo.svg';

import '../styles/footer.css';

const Footer = () => {
    return (
        <footer className="footer" style={{backgroundColor: '#f8f8f8'}}>
            <Container>
                <Row className="footer-main">
                    <Col md="3">
                        <div className="footer-content">
                            <img src={logo} alt="logo" className="logoFooter"/>
                            <p>A single unified digital bank for the diasporas, expats, travelers, and innovators worldwide.</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="footer-links">
                            <h3>Useful Links</h3>
                            <ul>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/signin">Sign In</Link></li>
                                <li><Link to="/signup">Sign Up</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="footer-links">
                            <h3>Social Links</h3>
                            <ul>
                                <li><a href="https://www.instagram.com"><FaInstagram/> Instagram</a></li>
                                <li><a href="https://www.facebook.com"><FaFacebook/> Facebook</a></li>
                                <li><a href="https://www.twitter.com"><FaTwitter/> Twitter</a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md="3">
                    <div className="footer-links">
                            <h3>Legal Links</h3>
                            <ul>
                                <li><Link to="/terms">Terms & Conditions</Link></li>
                                <li><Link to="/privacy">Privacy Policy</Link></li>
                                <li><Link to="/cookies">Cookies</Link></li>
                            </ul>
                        </div>
                    </Col>
                </Row>

            </Container>
            <hr/>

                    <div className="footer-bottom">
                        <p>&copy; Mattress Bank Inc. {new Date().getFullYear()}</p>
                    </div>

        </footer>
    );
};

export default Footer;
