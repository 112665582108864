import { configureStore } from '@reduxjs/toolkit';
import persistedAuthReducer from './redux/slices/auth.slice.ts';
import persistedProfileReducer from './redux/slices/profile.slice.ts';
import { persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';

const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        profile: persistedProfileReducer,
    },
    middleware: [thunk],
    devTools: false,
});

const persistor = persistStore(store);

export { store, persistor };
