import React, { useEffect, useState } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import '../styles/googleoauth.css'; 
import logo from '../images/logo.svg';

const GoogleOauth = () => {
  const [text, setText] = useState('');
  const fullText = 'Login With Google.';
  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
        setText(fullText.slice(0, index));
        index++;
        if (index > fullText.length) {
            clearInterval(intervalId);
        }
    }, 100);
  }, []);

  return (
    <div className='googleContainer'> 
      <div className="googleCard">
      <img src={logo} alt="Logo" className='logoGoogle' /> 
      <h3 className="heading">{text}</h3>
      <GoogleOAuthProvider clientId="1008002058258-520ij51djnm632e7sfgg52kcm5lkeopq.apps.googleusercontent.com">
        <GoogleLogin
          onSuccess={credentialResponse => {
            return;
          }}
          onError={() => {
            return;
          }}
        />
      </GoogleOAuthProvider>
      <button className="backButton" onClick={() => window.location.href = '/signup'}>
        Back to Sign Up
      </button>
    </div>
    </div>
    
  );
};

export default GoogleOauth;
