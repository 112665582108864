import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import logo from "../images/logo.jpg";

const GoogleSignUp = () => {
  const fullText = "Register to continue.";
  const [text, setText] = useState('');
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setText(fullText.slice(0, index));
      index++;
      if (index > fullText.length) {
        clearInterval(intervalId);
      }
    }, 100);
  }, []);

  const { handleGoogle, loading, error } = useFetch(
    `${REACT_APP_BASE_URL}user/google/signup`
  );

  useEffect(() => {
    /* global google */
    if (window.google) {
      const googleid = process.env.REACT_APP_GOOGLE_CLIENT_ID;
      google.accounts.id.initialize({
        client_id: googleid,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
        theme: "filled_black",
        text: "continue_with",
        shape: "pill",
      });
    }
  }, [handleGoogle]);

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <header style={styles.header}>
          <img src={logo} alt="Logo" style={styles.logo} />
          <h3 style={styles.headingGoogle}>{text}</h3>
        </header>
        <main style={styles.main}>
          {error && <p style={styles.error}>{error}</p>}
          {loading ? <div style={styles.loadingSpinner}></div> : <div id="signUpDiv"></div>}
        </main>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  card: {
    maxWidth: "400px",
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    textAlign: "center",
    backgroundColor: "white",
  },
  header: {
    textAlign: "center",
  },
  logo: {
    width: "50%",
    height: "50%",
    marginBottom: "2%",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  error: {
    color: "red",
  },
  headingGoogle: {
    fontSize: "1.3rem",
    color: "#27ca9f",
    marginBottom: "4%",
    marginTop: "2%",
  },
  loadingSpinner: {
    borderTop: '4px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    animation: 'spin 0.8s linear infinite',
    borderBottom: '4px solid rgba(0, 0, 0, 0.1)',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
  },
};

export default GoogleSignUp;
