import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, Input, Button, Spinner } from 'reactstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../styles/otp.css';
import { ToastContainer, toast } from 'react-toastify';
import AuthService from '../api/AuthService.tsx';
import { useDispatch } from 'react-redux';
import { login } from '../redux/slices/auth.slice.ts';
import { Helmet } from 'react-helmet-async';
import Cookies from 'js-cookie';


const OTPPage: React.FC = () => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [session, setSession] = useState<string>('');
    const resendTimeoutSeconds = 30;
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state && location.state.email ? location.state.email : '';
    const dispatch = useDispatch();

    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, otp.length);
    }, [otp.length]);

    const confirmLogin = async () => {
        try {

            setLoading(true);
            if (!email) {
                navigate('/signin')
                return;
            }

            const userId = await AuthService.getUserIdByEmail(email);

            if (userId) {
                const joinedOtp = otp.join('');
                const sessionID: any = await AuthService.verifyLoginUsingOTP({ userId, otp: joinedOtp });
                setSession(sessionID.sessionToken);
                Cookies.set('authToken', sessionID.sessionToken, { expires: 7 });
                dispatch(login({ email, userId, sessionToken: sessionID.sessionToken }))
                toast.success('OTP verified successfully!');
                navigate('/dashboard');
            } else {
                setError('User not found.');
                toast.error('User not found.');
            }
        } catch (error) {
            setError('Error verifying OTP. Please try again.');
            toast.error('Error verifying OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleOtpChange = (index: number, value: string) => {
        if (value.match(/^\d*$/)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (inputRefs.current[index + 1]) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleResendOtp = async () => {
        try {
            if (!email) {
                navigate('/signin')
                return;
            }
            setResendDisabled(true);
            setLoading(true);
            await AuthService.resendOTP(email);
            toast.success('OTP resent successfully!');
            setTimeout(() => {
                setResendDisabled(false);
                if (inputRefs.current[0]) {
                    inputRefs.current[0]?.focus();
                }
            }, resendTimeoutSeconds * 1000);
        } catch (error) {
            toast.error('Error resending OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='otp-page'>
            <Helmet>
                <title>OTP - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Card className="otp-card">
                <CardBody>
                    <h4 className="otp-heading">Verify login</h4>
                    {error && <p className="error">{error}</p>}
                    <div className='otp-input-group'>
                        {otp.map((digit, index) => (
                            <Input
                                key={index}
                                type="text"
                                value={digit}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                className="otp-input"
                                maxLength={1}
                                innerRef={(ref) => (inputRefs.current[index] = ref)}
                            />
                        ))}
                    </div>
                    {loading ? (<div className='spinner'>
                        <Spinner
                            color="primary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="secondary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="success"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="danger"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="warning"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="info"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="light"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="dark"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                    </div>) : (
                        <>
                            <div className="text-center mt-3">
                                <Button className='buttonSubmit' style={{ backgroundColor: '#27ca9f' }} onClick={confirmLogin} disabled={loading}>
                                    Verify OTP
                                </Button>
                            </div>
                            <div className="text-center mt-3">
                                <Button
                                    className='buttonSubmit'
                                    disabled={resendDisabled || loading}
                                    onClick={handleResendOtp}
                                >
                                    Resend OTP
                                </Button>
                            </div>
                            <div className="text-center mt-3">
                                <Link to='/signin' className="text-underline">
                                    Back to Signin
                                </Link>
                            </div>
                        </>
                    )}
                </CardBody>
            </Card>
            <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default OTPPage;
