import React, { useEffect, useState } from 'react';
import { Card, CardBody, Form, FormGroup, Label, Input, Button, Row, Col, Spinner } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../styles/profile.css';
import Header from '../components/header.tsx';
import Sidebar from '../components/sidebar.tsx';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Select from 'react-select';
import { ConfigProvider, DatePicker } from "antd";
import { faUser, faVenusMars, faBirthdayCake, faIdCard, faPhone, faHome, faGlobe, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/auth.slice.ts';
import UserService from '../api/UserService.tsx';
import TextArea from 'antd/es/input/TextArea';
import { ToastContainer, toast } from 'react-toastify';
import locale from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';

const UpdateUserPage = () => {
    const { user } = useSelector(selectAuth);
    const { userId } = user;
    const [isEditMode, setIsEditMode] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [nationalID, setNationalID] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [residentCountry, setResidentCountry] = useState('');
    const [genderString, setGenderString] = useState('');
    const [userData, setUserData] = useState({});
    const [dob, setDob] = useState('');
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);


    useEffect(() => {
        const getProfileData = async () => {
            try {
                setLoading(true)
                const response = await UserService.getUserProfile(userId);
                const data = response.data;
                if (JSON.stringify(data) !== JSON.stringify(userData)) {
                    setUserData(data);
                    setFirstName(data?.firstName || '');
                    setLastName(data?.lastName || '');
                    setGender(data?.gender || '');
                    setDateOfBirth(data?.dateOfBirth || '');
                    const formattedDate = formatDate(data?.dateOfBirth);
                    setDob(formattedDate);
                    setNationalID(data?.nationalID || '');
                    setPhoneNumber(data?.phoneNumber || '');
                    setAddress(data?.address || '');
                    setResidentCountry(data?.resident_country || '');
                }
            } catch (error) {
                toast.error('An Error occured while fetching your profile, please try again later');
            } finally {
                setLoading(false);
            }
        };

        getProfileData();
    }, [userId, userData]);

    const handleEditClick = () => {
        setIsEditMode(true);
    };

    const handleCancelClick = () => {
        setIsEditMode(false);
    };

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setIsEditMode(false);
    };


    const options = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'Other' },
    ];

    const handlePhoneNumberChange = (value: React.SetStateAction<string>) => {
        setPhoneNumber(value);
    };

    const handleGenderChange = (selectedOption: any) => {
        setGender(selectedOption);
        if (selectedOption && selectedOption.value) {
            setGenderString(selectedOption.value);
        }
    };

    const handleDateChange = (date: any, dateString: string) => {
        setDateOfBirth(dateString);
    }

    const formatDate = (inputDate: string | number | Date) => {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleProfileUpdate = async () => {
        try {
            setUpdateLoading(true);
            const updateData = {
                firstName,
                lastName,
                gender: genderString,
                phoneNumber,
                resident_country: residentCountry,
                address,
            }
            await UserService.updateUserInformation(userId, updateData);
            toast.success('Profile updated successfully');
        } catch (error) {
            toast.error('An Error occured while updating your profile, please try again later');
        } finally {
            setUpdateLoading(false);
        }
    }

    const calculateCompletionPercentage = (userData: { [s: string]: unknown; } | ArrayLike<unknown>) => {
        const filledFields = Object.values(userData).filter((value) => value !== "" && value !== "N/a").length;
        const totalFields = Object.keys(userData).length;

        const percentage = (filledFields / totalFields) * 100;

        return isNaN(percentage) ? 0 : percentage;
    };





    const completionPercentage = Math.floor(calculateCompletionPercentage(userData || 0));

    return (
        <>
            <Helmet>
                <title>Profile - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Header />
            <Sidebar />

            <div className="update-user-page">
                {loading ? (
                    <div className='spinner'>
                        <Spinner
                            color="primary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="secondary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="success"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="danger"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="warning"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="info"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="light"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="dark"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                    </div>
                ) : (
                    <Row>
                        <Col md={3}>
                            <div className="pie-chart">
                                <h4 className="update-user-heading">Personal Details</h4>
                                <CircularProgressbar
                                    value={completionPercentage}
                                    text={`${completionPercentage}% completed`}
                                    styles={buildStyles({
                                        textColor: '#000',
                                        pathColor: '#27ca9f',
                                        trailColor: '#E0E0E0',
                                        textSize: '10px'
                                    })}
                                />
                            </div>
                        </Col>
                        <Col md={9}>
                            <div className="card-container">
                                <Card className="update-user-card">
                                    <CardBody>
                                        {isEditMode ? (
                                            <Form onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="firstName">First Name</Label>
                                                            <Input
                                                                type="text"
                                                                id="firstName"
                                                                name="firstName"
                                                                value={firstName}
                                                                placeholder='Enter first name'
                                                                onChange={(e) => setFirstName(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="lastName">Last Name</Label>
                                                            <Input
                                                                type="text"
                                                                id="lastName"
                                                                name="lastName"
                                                                value={lastName}
                                                                placeholder='Enter last name'
                                                                onChange={(e) => setLastName(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="gender">Gender</Label>
                                                            <Select
                                                                id="gender"
                                                                name="gender"
                                                                value={options.find(option => option.value === gender)}
                                                                options={options}
                                                                onChange={handleGenderChange}
                                                                required
                                                                className='dropdownProfile'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="dateOfBirth">Date Of Birth</Label>
                                                            {/* <br /> */}
                                                            <ConfigProvider locale={locale}>
                                                                <DatePicker
                                                                    onChange={handleDateChange}
                                                                    className='datePicker'
                                                                    disabled={dateOfBirth ? true : false}
                                                                    defaultValue={dayjs(`${dob}`, 'YYYY-MM-DD')}
                                                                />
                                                            </ConfigProvider>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="nationalID">National Identity</Label>
                                                            <Input
                                                                type="text"
                                                                id="nationalID"
                                                                name="nationalID"
                                                                value={nationalID}
                                                                placeholder='Enter national ID'
                                                                onChange={(e) => setNationalID(e.target.value)}
                                                                required
                                                                disabled={nationalID ? true : false}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="phoneNumber">Phone Number</Label>
                                                            <PhoneInput
                                                                country={'us'}
                                                                value={phoneNumber}
                                                                onChange={handlePhoneNumberChange}
                                                                inputStyle={{ width: '100%', height: '40px' }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="address">Residential Address</Label>
                                                            <TextArea
                                                                id="address"
                                                                name="address"
                                                                value={address}
                                                                placeholder='Enter residential address'
                                                                onChange={(e) => setAddress(e.target.value)}
                                                                required
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="residentCountry">Residential Country</Label>
                                                            <TextArea
                                                                id="residentCountry"
                                                                name="residentCountry"
                                                                placeholder='Enter residential country'
                                                                value={residentCountry}
                                                                onChange={(e) => setResidentCountry(e.target.value)}
                                                                required
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {updateLoading ? (<Spinner color="primary" type="grow" />) : (
                                                    <>
                                                        <Button type="submit" className="buttonSubmit" onClick={handleProfileUpdate}>
                                                            Save Changes
                                                        </Button>
                                                        <Button type="button" onClick={handleCancelClick} className="buttonSubmit">
                                                            Cancel
                                                        </Button>
                                                    </>
                                                )}
                                            </Form>
                                        ) : (
                                            <div className="data-display">
                                                <Row>
                                                    <Col md={6} className="data-field">
                                                        <p><FontAwesomeIcon icon={faUser} /> <strong>First Name:</strong> {firstName || 'N/a'}</p>
                                                    </Col>
                                                    <Col md={6} className="data-field">
                                                        <p><FontAwesomeIcon icon={faUser} /> <strong>Last Name:</strong> {lastName || 'N/a'}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} className="data-field">
                                                        <p><FontAwesomeIcon icon={faVenusMars} /> <strong>Gender : </strong> {gender || 'N/a'}</p>
                                                    </Col>
                                                    <Col md={6} className="data-field">
                                                        <p><FontAwesomeIcon icon={faBirthdayCake} /> <strong>Date Of Birth : </strong> {dob || 'N/a'}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} className="data-field">
                                                        <p><FontAwesomeIcon icon={faIdCard} /> <strong>National Identity : </strong> {nationalID || 'N/a'}</p>
                                                    </Col>
                                                    <Col md={6} className="data-field">
                                                        <p><FontAwesomeIcon icon={faPhone} /> <strong>Phone Number : </strong> {phoneNumber || 'N/a'}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} className="data-field">
                                                        <p><FontAwesomeIcon icon={faHome} /> <strong>Residential Address : </strong> {address || 'N/a'}</p>
                                                    </Col>
                                                    <Col md={6} className="data-field">
                                                        <p><FontAwesomeIcon icon={faGlobe} /> <strong>Residential Country : </strong> {residentCountry || 'N/a'}</p>
                                                    </Col>
                                                </Row>
                                                <Button onClick={handleEditClick} className="edit-button">
                                                    Edit Personal Information <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            </div>

                                        )}
                                    </CardBody>

                                </Card>
                                <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                            </div>
                        </Col>
                    </Row>)}
            </div>
        </>
    );
};

export default UpdateUserPage;


