// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.googleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5; 
  }
  
  .googleCard {
    width: 450px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    background-color: white;
  }
  
  .logoGoogle {
    width: 50%; 
    height: 50%; 
    margin-bottom: 20px;
  }
  
  .backButton {
    margin-top: 20px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    background-color: #27ca9f; 
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .backButton:hover {
    background-color: #27ca9f; 
  }
  
  .googleHeader{
    margin-top: 1%;
    margin-bottom: 4%;
    font-size: 25px;
    font-weight: 500;

  }`, "",{"version":3,"sources":["webpack://./src/styles/googleoauth.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,wCAAwC;IACxC,kBAAkB;IAClB,kBAAkB;IAClB,uBAAuB;EACzB;;EAEA;IACE,UAAU;IACV,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,gBAAgB;;EAElB","sourcesContent":[".googleContainer {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n    background-color: #f5f5f5; \r\n  }\r\n  \r\n  .googleCard {\r\n    width: 450px;\r\n    padding: 20px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    border-radius: 8px;\r\n    text-align: center;\r\n    background-color: white;\r\n  }\r\n  \r\n  .logoGoogle {\r\n    width: 50%; \r\n    height: 50%; \r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .backButton {\r\n    margin-top: 20px;\r\n    padding: 8px 16px;\r\n    font-size: 16px;\r\n    cursor: pointer;\r\n    background-color: #27ca9f; \r\n    color: white;\r\n    border: none;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .backButton:hover {\r\n    background-color: #27ca9f; \r\n  }\r\n  \r\n  .googleHeader{\r\n    margin-top: 1%;\r\n    margin-bottom: 4%;\r\n    font-size: 25px;\r\n    font-weight: 500;\r\n\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
