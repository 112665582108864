import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, NavbarToggler, Collapse } from 'reactstrap';
import { FaSignInAlt, FaUserPlus } from 'react-icons/fa';
import logo from '../images/logo.svg';
import '../styles/mobileNavbar.css';

const MobileNavbar = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Navbar color="light" light expand="md" className="mobile-navbar fixed-top">
            <NavLink to="/" className="mobile-navbar-brand">
                <img src={logo} alt="logo" />
            </NavLink>
            <NavbarToggler onClick={toggleNavbar} />
            <Collapse isOpen={isOpen} navbar className="collapibleMenu">
                <Nav className="ml-auto" navbar>
                    <NavLink to="/about" className="mobile-nav-link">
                        About Us
                    </NavLink>
                    <NavLink to="/privacy" className="mobile-nav-link">
                        Privacy Policy
                    </NavLink>
                    <NavLink to="/terms" className="mobile-nav-link">
                        Terms and Conditions
                    </NavLink>
                    <NavLink to="/signin" className="mobile-buttonNav">
                        Log In <FaSignInAlt />
                    </NavLink>
                    <NavLink to="/signup" className="mobile-buttonNav ghost-button mt-2">
                        Sign Up <FaUserPlus />
                    </NavLink>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default MobileNavbar;
