// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-bar-container {
    width: 100%;
    height: 3px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f0f0f0;
    z-index: 9999;
}

.loading-bar {
    width: 0%;
    height: 100%;
    background-color: #27ca9f;
    transition: width 0.5s ease;
    animation: loadingAnimation 1s linear infinite;
}

@keyframes loadingAnimation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/auth-loading.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,eAAe;IACf,MAAM;IACN,OAAO;IACP,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,yBAAyB;IACzB,2BAA2B;IAC3B,8CAA8C;AAClD;;AAEA;IACI;QACI,SAAS;IACb;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".loading-bar-container {\r\n    width: 100%;\r\n    height: 3px;\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    background-color: #f0f0f0;\r\n    z-index: 9999;\r\n}\r\n\r\n.loading-bar {\r\n    width: 0%;\r\n    height: 100%;\r\n    background-color: #27ca9f;\r\n    transition: width 0.5s ease;\r\n    animation: loadingAnimation 1s linear infinite;\r\n}\r\n\r\n@keyframes loadingAnimation {\r\n    0% {\r\n        width: 0%;\r\n    }\r\n    100% {\r\n        width: 100%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
