import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Confetti from 'react-confetti';
import '../styles/transferSuccess.css';
import Footer from '../components/footer.tsx';
import Header from '../components/header.tsx';
import Sidebar from '../components/sidebar.tsx';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const TransferSuccessPage = () => {

    const formatCurrency = (amount: number | bigint) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        }).format(amount);
    };
    const location = useLocation();

    if (location.state === undefined) {
        window.location.href = "/";
    }

    return (
        <>
            <Helmet>
                <title>Success - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Header />
            <Sidebar />
            <Confetti numberOfPieces={200} recycle={false} />
            <div className="transfer-success-container">
                <Card className="transfer-success-card">
                    <CardBody>

                        <div className="review-details">
                            <h5>Transfer Successful</h5>
                            <p>
                                <strong>Transfer To:</strong> {location.state.selectedAccount}
                            </p>
                            <p>
                                <strong>Amount:</strong> {formatCurrency(location.state.transferAmount)}
                            </p>
                            <p>
                                <strong>Reference Number:</strong> {location.state.referenceNumber}
                            </p>
                            <p>
                                <strong>New Account Balance:</strong>{' '}

                                {formatCurrency(location.state.newBalance)}
                            </p>
                        </div>
                    </CardBody>
                </Card>

            </div>
            {/*<Footer />*/}
        </>
    );
};

export default TransferSuccessPage;
