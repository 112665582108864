import React, { useState, useEffect } from 'react';
import { Card, CardBody, Form, Input, Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/signup.css';
import logo from '../images/logo.svg';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../api/AuthService.tsx';
import { useAuth } from '../auth/authContext.tsx';
import { Helmet } from 'react-helmet-async';

const Signup: React.FC = () => {
    const { setCredentials } = useAuth();
    const navigate = useNavigate();
    const [email, setEmailOnPage] = useState('');
    const [password, setPasswordOnPage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fullText = 'Create Account.';

    useEffect(() => {
        let index = 0;
        const intervalId = setInterval(() => {
            setText(fullText.slice(0, index));
            index++;
            if (index > fullText.length) {
                clearInterval(intervalId);
            }
        }, 100);
    }, []);

    const isEmailValid = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isPasswordValid = (password: string) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
        return passwordRegex.test(password);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    const handleSignup = async () => {
        try {
            if (!isEmailValid(email)) {
                setError('Please enter a valid email address.');
                toast.error('Please enter a valid email address.');
                return;
            }

            if (!isPasswordValid(password)) {
                setError('Password must be at least 8 characters with one capital letter, one special character, and one number.');
                toast.error('Password must be at least 8 characters with one capital letter, one special character, and one number.');
                return;
            }

            if (password !== confirmPassword) {
                setError('Passwords do not match.');
                toast.error('Passwords do not match.');
                return;
            }
            setLoading(true);
            const isEmailAvailable = await AuthService.checkEmail(email);
            if (isEmailAvailable.success) {
                setCredentials(email, password);
                navigate('/onboarding');
            } else {
                setError(isEmailAvailable.message);
                toast.error(isEmailAvailable.message);
            }
        } catch (err) {
            setError(err.message);
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleOauth = async () => {
        navigate('/google/register');
    }

    const handleInputChange = () => {
        setError('');
    };


    return (
        <div className="signup-page">
            <Helmet>
                <title>Sign Up - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Card className="signup-card">
                <CardBody>
                    <img src={logo} alt="logo" className="logo" />
                    <h4 className="heading">{text}</h4>
                    <div className="formGroup">
                        <Form>
                            <Input
                                type="email"
                                placeholder="Enter your email address"
                                value={email}
                                onChange={(e) => { setEmailOnPage(e.target.value); handleInputChange() }}
                                className="input"
                            />
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => { setPasswordOnPage(e.target.value); handleInputChange() }}
                                className="input"
                            />
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Confirm your password"
                                value={confirmPassword}
                                onChange={(e) => { setConfirmPassword(e.target.value); handleInputChange() }}
                                className="input"
                            />
                            {/* <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className="eye-icon"
                                onClick={handleTogglePasswordVisibility}
                            /> */}
                            {error && <div style={{ color: "#ff0000" }}>{error}</div>}
                            {loading ? (<div className='spinner'>
                                <Spinner
                                    color="primary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="secondary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="success"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="danger"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="warning"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="info"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="light"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="dark"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>) : (
                                <>
                                    <Button
                                        className="buttonSubmit"
                                        style={{ backgroundColor: '#27ca9f' }}
                                        onClick={handleSignup}
                                        disabled={loading}
                                    >
                                        Next
                                    </Button>
                                    <div className="orText mt-2 mb-2">- Or -</div>
                                    <Button className="googleButton" onClick={handleGoogleOauth}>
                                        <FontAwesomeIcon icon={faGoogle} className="googleIcon" />
                                        Sign up with Google
                                    </Button>
                                </>)}
                        </Form>
                        <div className="text-center mt-3">
                            <Link to="/signin" className="text-underline">
                                Sign In
                            </Link>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <div className="signup-image" style={{ width: '50%', height: '100%' }}></div>
            <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default Signup;
