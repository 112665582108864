import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import '../styles/graph.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/auth.slice.ts';
import { toast } from 'react-toastify';
import AccountsService from '../api/AccountsService.tsx';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};




const FinancialGraph = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(selectAuth);
    const { userId } = user;
    const [depositTransactions, setDepositTransactions] = useState([]);
    const [withdrawalTransactions, setWithdrawalTransactions] = useState([]);
    const [transferTransactions, setTransferTransactions] = useState([]);

    useEffect(() => {

        const getUserDeposits = async () => {
            try {
                const response = await AccountsService.getUserDepositTransactions(userId);
                const deposits = response?.depositTransactions || [];
                setDepositTransactions(deposits);
            } catch (error) {
                toast.error('An Error occurred while fetching your deposits, please try again later');
            }
        };


        const getUserTransfers = async () => {
            try {
                const response = await AccountsService.getUserTransferTransactions(userId);
                const transfers = response?.transferTransactions || [];
                setTransferTransactions(transfers);
            } catch (error) {
                toast.error('An Error occurred while fetching your transfers, please try again later');
            }
        };



        const getUserWithdrawals = async () => {
            try {
                const response = await AccountsService.getUserWithdrawalTransactions(userId);
                const withdrawals = response?.withdrawalTransactions || [];
                setWithdrawalTransactions(withdrawals);;
            } catch (error) {
                toast.error('An Error occurred while fetching your withdrawals, please try again later');
            }
        };


        getUserDeposits();
        getUserTransfers();
        getUserWithdrawals();

    }, [userId]);


    const depositData = depositTransactions.map((transaction: { amount: number }) => transaction.amount);
    const transferData = transferTransactions.map((transaction: { amount: number }) => transaction.amount);
    const withdrawalData = withdrawalTransactions.map((transaction: { amount: number }) => transaction.amount);

    const labels = ['Deposits', 'Transfers', 'Withdrawals'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Amount',
                data: [depositData.reduce((acc, val) => acc + val, 0), transferData.reduce((acc, val) => acc + val, 0), withdrawalData.reduce((acc, val) => acc + val, 0)],
                backgroundColor: 'rgba(39, 202, 159, 1)',
            },
        ],
    };

    return (
        <div className="financial-graph">
            <h2 style={{ textAlign: 'center' }}>Financial Overview</h2>
            <Bar data={data} options={options} />
        </div>
    );
};

export default FinancialGraph;
