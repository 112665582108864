// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFoundContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .container {
    width: 450px;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .card{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    padding: 20px;
  }
  
  .notFoundHeader {
    color: #555;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .notFoundParagraph {
    color: #555;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/404.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,oCAAoC;IACpC,aAAa;EACf;;EAEA;IACE,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".notFoundContainer {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n  }\r\n  \r\n  .container {\r\n    width: 450px;\r\n    text-align: center;\r\n    padding-top: 5%;\r\n    padding-bottom: 5%;\r\n  }\r\n\r\n  .card{\r\n    background-color: #fff;\r\n    border-radius: 10px;\r\n    box-shadow: 0 0 10px rgba(0,0,0,0.2);\r\n    padding: 20px;\r\n  }\r\n  \r\n  .notFoundHeader {\r\n    color: #555;\r\n    font-size: 30px;\r\n    font-weight: 700;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .notFoundParagraph {\r\n    color: #555;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
