import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './pages/SignIn.tsx';
import Signup from './pages/SignUp.tsx';
import OTPPage from './pages/Otp.tsx';
import ForgotPassword from './pages/ForgotPassword.tsx';
import Home from './pages/Home.tsx';
import ProfilePage from './pages/ProfilePage.tsx';
import OnboardingPage from './pages/Onboarding.tsx';
import MoneyTransfer from './pages/Transfer.tsx';
import TransactionHistory from './pages/Transactions.tsx';
import Beneficiaries from './pages/Beneficiaries.tsx';
import HelpMe from './pages/Help.tsx';
import AccountVerificationPage from './pages/AccountVerification.tsx';
import PrivateRoute from './auth/privateRoute.tsx';
import BankingSettings from './pages/Settings.tsx';
import SuccessPage from './pages/SuccessfulTransfer.tsx';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth, logout } from './redux/slices/auth.slice.ts';
import GoogleOauth from './auth/googleAuth.js';
import GoogleSignUp from './pages/GoogleSignUp.jsx';
import GoogleLogin from './pages/GoogleLogin.jsx';
import NotFoundPage from './pages/NotFound.tsx';
import LandingPage from './pages/LandingPage.tsx';
import {clearProfile} from "./redux/slices/profile.slice.ts";
import AuthService from "./api/AuthService.tsx";
import TermsConditions from "./pages/TermsConditions.tsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.tsx";


const idleTimerWorker = new Worker(new URL('./middlewares/IdleTimerWorker.js', import.meta.url));

const App = () => {
  const { isAuthenticated } = useSelector(selectAuth);
  const { user } = useSelector(selectAuth);
  const { userId, sessionToken } = user;

  const helmetContext = {};
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await AuthService.logoutUser({
        userId,
        sessionToken,
      });
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };


  useEffect(() => {
    idleTimerWorker.postMessage('start');

    return () => {
      idleTimerWorker.postMessage('stop');
    };
  }, []);

  useEffect(() => {
    idleTimerWorker.onmessage = async (event) => {
      if (event.data === 'timeout' && isAuthenticated) {
        try {
          await handleLogout();
          await dispatch(logout());
          await dispatch(clearProfile());
          window.location.href = '/signin';
        } catch (error) {
          console.error('Logout Process Error:', error);
        }
      }
    };
  }, [dispatch, isAuthenticated]);




  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <Fragment>
          <div className="app">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/terms" element={<TermsConditions />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/verify-account" element={<AccountVerificationPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/otp" element={<OTPPage />} />
              <Route path="/onboarding" element={<OnboardingPage />} />
              <Route path="/google/oauth" element={<GoogleOauth />} />
              <Route path="/google/login" element={<GoogleLogin />} />
              <Route path="/google/register" element={<GoogleSignUp />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<Home />} />
                <Route path="/money-transfer" element={<MoneyTransfer />} />
                <Route path="/transactions" element={<TransactionHistory />} />
                <Route path="/beneficiaries" element={<Beneficiaries />} />
                <Route path="/help" element={<HelpMe />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="/settings" element={<BankingSettings />} />
                <Route path="/success" element={<SuccessPage />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Fragment>
      </Router>
    </HelmetProvider>
  );
};

export default App;
