/* eslint-disable import/no-anonymous-default-export */
import ApiClient from "./ApiClient.tsx";
class AuthService {

    login = async (payload: { email: string; password: string }) => {
        try {
            const response: any = await ApiClient().post("user/login", payload);

            if (!response) {
                const data = await response.data;
                throw new Error(data.message);
            }

            const data = await response.data;
            const user = data.user;

            return user;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    logoutUser = async (logoutData: { userId: number, sessionToken: string }) => {
        try {
            await ApiClient().post("user/logout", logoutData);

            return { success: true, message: 'Logout successful' };
        } catch (error) {
            if (error.response && error.response.status === 404) {
                throw new Error('Session not found');
            }

            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    register = async (payload: { email: any; password: any; firstName: string; lastName: string; gender: string; dateOfBirth: string; nationalID: string; phoneNumber: string; address: string; residentCountry: string; taxIdentifier: string; username: string; }) => {
        try {
            const response: any = await ApiClient().post("user/register", payload);

            if (!response) {
                const data = await response.data;
                throw new Error(data.message);
            }

            const data = await response.data;
            const user = data.user;
            return user;
        } catch (error) {
            throw new Error(error.message);
        }
    };



    checkEmail = async (email: string) => {
        try {
            const response: any = await ApiClient().get(`user/checkEmail/${email}`);

            if (!response) {
                const data = await response.data;
                throw new Error(data.message);
            }

            const data = await response.data;

            return data;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    verifyAccount = async (payload: { userId: number, otp: string }) => {
        try {
            const response: any = await ApiClient().post("user/verify-account", payload);

            if (!response) {
                const data = await response.data;
                throw new Error(data.message);
            }

            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    resendOTP = async (userName: string) => {
        try {
            const response: any = await ApiClient().get(`user/resend-otp/${userName}`);

            if (!response) {
                const data = await response.data;
                throw new Error(data.message);
            }

            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    forgotPassword = async (userName: string) => {
        try {
            const response: any = await ApiClient().get(`user/forgot-password/${userName}`);

            if (!response) {
                const data = await response.data;
                throw new Error(data.message);
            }

            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    validateOTP = async (payload: { userId: number, otp: string }) => {
        try {
            const response: any = await ApiClient().post("user/validate-otp", payload);

            if (!response) {
                const data = await response.data;
                throw new Error(data.message);
            }

            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    verifyLoginUsingOTP = async (payload: { userId: number, otp: string }) => {
        try {
            const response: any = await ApiClient().post("user/verify-login", payload);

            if (!response) {
                const data = await response.data;
                throw new Error(data.message);
            }

            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    };


    getUserIdByUsername = async (username: string): Promise<number | null> => {
        try {
            const response: any = await ApiClient().get(`user/get-user-id/byUsername/${username}`);

            if (!response) {
                const data = await response.data;
                throw new Error(data.message);
            }

            const data = await response.data;
            return data.userId;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    getUserIdByEmail = async (email: string): Promise<number | null> => {
        try {
            const response: any = await ApiClient().get(`user/get-user-id/byEmail/${email}`);

            if (!response) {
                const data = await response.data;
                throw new Error(data.message);
            }

            const data = await response.data;

            return data.data;
        } catch (error) {
            throw new Error(error.message);
        }
    };
}

export default new AuthService();
