/* eslint-disable import/no-anonymous-default-export */
import { ApiResponse } from "../types";
import ApiClient from "./ApiClient.tsx";

class UserService {

    changePassword = async (changePasswordData: { userId: number; oldPassword: string; newPassword: string }) => {
        try {
            const response: ApiResponse = await ApiClient().post("user/change-password", changePasswordData);

            if (!response.data) {
                const data = await response.data;
                throw new Error(data.message || 'Unknown error during password change');
            }

            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                throw new Error('Invalid previous password');
            }

            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    getUserProfile = async (userId: number) => {
        try {
            const response: ApiResponse = await ApiClient().get(`user/profile/${userId}`);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                throw new Error('User not found');
            }

            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    isAccountVerified = async (userId: number) => {
        try {
            const response: ApiResponse = await ApiClient().get(`user/account-verified/${userId}`);

            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                throw new Error('User not found');
            }

            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    updateUserInformation = async (userId: number, updateData: any) => {
        try {
            const response: ApiResponse = await ApiClient().patch(`user/update/${userId}`, updateData);

            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                throw new Error('User not found');
            }

            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };

    forgotPassword = async (userName: string) => {
        try {
            const response: ApiResponse = await ApiClient().get(`user/forgot-password/${userName}`);

            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                throw new Error('User not found');
            }

            throw new Error('Sorry, something went wrong. Please try again later');
        }
    };
}

export default new UserService();
