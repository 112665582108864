import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  email: '',
  password: '',
};

const AuthContext = createContext({});

const authReducer = (state: any, action: { type: any; payload: { email: string; password: string; }; }) => {
  switch (action.type) {
    case 'SET_CREDENTIALS':
      return { ...state, email: action.payload.email, password: action.payload.password };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const setCredentials = (email: any, password: any) => {
    dispatch({ type: 'SET_CREDENTIALS', payload: { email, password } });
  };

  return (
    <AuthContext.Provider value={{ ...state, setCredentials }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
